import holidays from '../holidays'
import moment from 'moment'

export default {
  data: () => ({
    disabledDatesMixin: {
      to: moment().endOf('d').toDate(),
      from: moment().add({ months: 6 }).toDate(), // disable any date after 6 months from now
      customPredictor: holidays.customPredictor,
    },
    victorDisabledDatesMixin: {
      to: moment().startOf('d').toDate(), // allow all days from today
      from: moment().add({ months: 6 }).toDate(), // disable any date after 6 months from now
    },
  }),
  methods: {
    modifyStartDateMixin(insurer = null, startDate = null) {
      const isVictor = insurer?.includes('victor')
      const disabledDates = insurer?.includes('victor') ? 
        this.victorDisabledDatesMixin : this.disabledDatesMixin

      // with victor we can have any date we want
      if (disabledDates.customPredictor && disabledDates.customPredictor(startDate)) {
        do {
          startDate = moment(startDate).add(1, 'days').hours(12).toDate()
        } while (disabledDates.customPredictor && disabledDates.customPredictor(startDate))
      }
      return isVictor ? moment().toDate() : startDate
    },
  },
}
