<template>
  <div
    :class="[
      {
        'market-comparison--offer': isOffer,
        'col-has-shadow': showShadow,
        'insured-sum-options-show': $store.getters.insuredSumOptionsAllowed && $store.state.offer.showInsuredSumOptions,
        'table-header-sticky': isTblHeaderSticky,
      },
      `insurers-qnty-${insurers.length}`,
    ]"
    :style="`--shadow-col-width: ${shadowColWidth}`"
  >
    <table v-if="mode !== 'offer' || (mode === 'offer' && isInitialDataLoaded)" class="the-table">
      <thead>
        <tr>
          <td>
            <div class="header-filler"></div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <div class="form-wrapper">
            <form
              v-if="isInitialDataLoaded"
              v-on:submit.prevent.stop="submitForm()"
              name="step_produktvergleich"
              id="step_form"
              :class="{ compressed: compressedMode }"
            >
              <!-- next block takes too much space if visible -->
              <div v-if="!isOffer" class="filter resume-info mb-md-4">
                <div class="details sticky-col">
                  <div class="details__title">
                    <span class="font-weight-bold mr-4">Produktdetails</span>
                    <ButtonComponent
                      v-if="isWWW"
                      class="icon-link d-print-none"
                      btn-style="link-primary"
                      :icon="{
                        name: 'arrow-full-right',
                        position: 'right',
                      }"
                      link="#"
                      @click.native.prevent="goBack()"
                      >Zur Ergebnisliste</ButtonComponent
                    >
                    <ButtonComponent
                      v-if="!isWWW && !isOffer"
                      class="icon-link d-print-none"
                      btn-style="link-primary"
                      :icon="{
                        name: 'arrow-full-right',
                        position: 'right',
                      }"
                      behavior="external"
                      openNewTab="false"
                      link="#"
                      @click.native.prevent="goBack()"
                      >Zur komprimierten Darstellung</ButtonComponent
                    >
                  </div>
                  <div>
                    <div>
                      {{ inputs.industry.l }}
                    </div>
                    <div class="revenue-range">
                      Jahresumsatz
                      <span class="revenue-range__number">
                        {{ revenueRange.start | currency(0) }} -
                        {{ revenueRange.end | currency(0) }}
                      </span>
                    </div>
                    <div v-if="isOffer || isWWW" :class="{ 'd-print-none': isOffer }">
                      <div :class="{ 'd-none': isWWW }">Versicherungssumme {{ inputs.insuredSum | currency(0) }}</div>
                      <div class="deductible">
                        Selbstbehalt <span class="deductible__number">{{ inputs.deductible | currency(0) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- :downloadLink="downloadLink" -->
              <OfferDetailsCard
                v-if="isOffer"
                class="filter--mobile"
                :isWWW="isWWW"
                :isOffer="isOffer"
                :isAffiliate="isAffiliate"
                :inputs="inputs"
                :revenueRange="revenueRange"
                :allowRatingsSwitch="allowRatingsSwitch"
                :isScoreVisible="isScoreVisible"
                :sortBy="sortBy"
                :sortByOptions="sortByOptions"
                @sortChange="sortChange"
                :isMobile="true"
              ></OfferDetailsCard>

              <div
                ref="filterRowWrapper"
                name="trTable"
                class="filter-row-wrapper syncscroll"
                :class="{ 'filter-row-wrapper--sticky': isTblHeaderSticky }"
              >
                <div class="wrap-helper">
                  <div class="filter-row filter-row gap-sm" :class="{ 'filter-row--not-offer': !isOffer }">
                    <div class="filter-options sticky-col d-print-none extra-z-index">
                      <Card v-if="!isOffer">
                        <div class="filter-option form-group">
                          <label for="insured-sum">Versicherungssumme</label>
                          <SelectComponent id="insured-sum" v-model="inputs.insuredSum" data-type="Number">
                            <option :value="s" v-for="(s, index) in insuredSums" :key="index">
                              {{ s | currency(0) }}
                            </option>
                          </SelectComponent>
                        </div>
                        <div v-if="!isOffer" class="filter-option form-group">
                          <label for="deductible">Selbstbehalt</label>
                          <SelectComponent
                            v-if="possibleDeductibles.length > 1"
                            id="deductible"
                            v-model="inputs.deductible"
                            data-type="Number"
                          >
                            <option :value="d" v-for="(d, index) in possibleDeductibles" :key="index">
                              {{ d | currency(0) }}
                            </option>
                          </SelectComponent>
                          <input
                            v-else
                            id="deductible"
                            class="form-control-plaintext pl-2"
                            type="text"
                            readonly
                            :value="inputs.deductible | currency(0)"
                          />
                        </div>
                        <div class="filter-option form-group">
                          <label for="payment-periods">Zahlweise</label>

                          <SelectComponent id="payment-periods" v-model="inputs.paymentPeriod">
                            <option :value="p.value" v-for="(p, index) in paymentPeriods" :key="index">
                              {{ p.label }}
                            </option>
                          </SelectComponent>
                        </div>
                        <div class="filter-option form-group">
                          <label for="optional-blocks">
                            Optionale Bausteine
                            <button
                              v-if="(isBroker && mode !== 'offer') || isWWW"
                              type="button"
                              class="filter-option__info-btn btn btn-icon"
                              @click="showOptModuleInfoModal = true"
                            >
                              <icon name="info" width="18" height="18"></icon>
                            </button>
                          </label>
                          <SelectComponent id="optional-blocks" v-model="selectedModulesType">
                            <option :value="m.value" v-for="(m, index) in moduleModes" :key="index">
                              {{ m.label }}
                            </option>
                          </SelectComponent>
                        </div>
                        <div v-show="insurers.length > 1" class="filter-option form-group">
                          <label for="sort-by"
                            >Sortieren nach
                            <button type="button" class="filter-option__info-btn btn btn-icon" @click="showSortInfoModal = true">
                              <icon name="info" width="18" height="18"></icon>
                            </button>
                          </label>
                          <SelectComponent id="sort-by" v-model="sortBy" @input="sortChange">
                            <option v-for="sortByOpt in sortByOptions" :key="`sort-by-opt${sortByOpt.value}`" :value="sortByOpt.value">
                              {{ sortByOpt.label }}
                            </option>
                          </SelectComponent>

                          <!-- can be deleted at 2024-2-16-->
                          <NewFeature :showUntil="new Date('2024-2-16')" :featureId="'price-score-notification-shown'"> </NewFeature>
                        </div>
                        <div v-if="allowRatingsSwitch" class="filter-option form-group filter-option--checkbox">
                          <label for="scores-visibility">Alles einblenden</label>
                          <b-form-checkbox
                            class="cd-control checkbox-accent control"
                            id="scores-visibility"
                            name="scores-visibility"
                            v-model="isScoreVisible"
                          >
                          </b-form-checkbox>
                          <!-- <Button behavior="button" btn-style="link-primary" class="text-sm">Alles einblenden</Button> -->
                        </div>
                        <div class="view-type-switch filter-option mb-4">
                          <InfoPopup />
                          <span class="label mr-2">Vergleich: Bedingungen</span>
                          <b-form-checkbox v-model="isQuestionsComparisonView" name="tariff-type" size="lg" switch class="custom-position"> </b-form-checkbox>
                          <span class="label"> Risikofragen </span>
                        </div>
                        <ButtonComponent
                          v-if="(isBroker && mode !== 'offer') || (isAffiliate && mode !== 'offer')"
                          btn-style="accent"
                          behavior="button"
                          class="apply-btn mt-3"
                          @click.native="createOffer()"
                          :disabled="offerSelection.length === 0"
                          type="button"
                          >{{ offerText }}
                          <span v-if="insurers.length > maxOffers">&nbsp; (max. {{ maxOffers }})</span>
                        </ButtonComponent>
                      </Card>

                      <!-- :downloadLink="downloadLink" -->
                      <OfferDetailsCard
                        v-else
                        :isWWW="isWWW"
                        :isOffer="isOffer"
                        :isAffiliate="isAffiliate"
                        :inputs="inputs"
                        :revenueRange="revenueRange"
                        :allowRatingsSwitch="allowRatingsSwitch"
                        :isScoreVisible="isScoreVisible"
                        :sortByOptions="sortByOptions"
                        :sortBy="sortBy"
                        @sortChange="sortChange"
                      ></OfferDetailsCard>
                    </div>
                    <div class="filter-companies">
                      <Card class="d-flex p-0">
                        <Insurers
                          ref="insurersComponent"
                          class="d-print-none"
                          :inputs="inputs"
                          :key="'insurers-' + insurers.length"
                          :insurers="!isPrintMode ? insurers : printInsurers"
                          :synaxon="synaxon"
                          :checkoutButtonIsVisible="checkoutButtonIsVisible"
                          :affiliateBroker="affiliateBroker"
                          :order1="order1Mixin"
                          :order2="order2Mixin"
                          :isBroker="isBroker"
                          :mode="mode"
                          :offerSelection="offerSelection"
                          :maxOffers="maxOffers"
                          @updateOfferSelection="updateOfferSelection"
                          @selectInsurer="selectInsurer"
                          @setModalBody="setModalBody"
                          :isOffer="isOffer"
                          :isAffiliate="isAffiliate"
                        ></Insurers>
                        <div class="nav-btns">
                          <!-- <NavBtn nav-type="prev" class="carousel-btn-prev"></NavBtn>
                            <NavBtn navType="next" class="carousel-btn-next"></NavBtn> -->
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div class="insured-sum-row" v-if="$store.getters.insuredSumOptionsAllowed && $store.state.offer.showInsuredSumOptions">
                    <div class="insured-sum-filler">
                      <div
                        v-if="$store.getters.insuredSumOptionsAllowed && $store.state.offer.showInsuredSumOptions"
                        class="resume-card__footer insured-sum-options-v2"
                      >
                        <div v-if="$store.state.offer?.offer?.insuredSumOption1" class="insured-sum-option">
                          <span class="text1">Alternative 1:</span>
                          <span>Versicherungssumme {{ $store.state.offer?.offer?.insuredSumOption1 | currency(0) }}</span>
                        </div>
                        <div v-if="$store.state.offer?.offer?.insuredSumOption2" class="insured-sum-option">
                          <span class="text1">Alternative 2:</span>
                          <span>Versicherungssumme {{ $store.state.offer?.offer?.insuredSumOption2 | currency(0) }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="insured-sum-options insurers">
                      <div
                        class="insured-sum-options__insurer"
                        :class="[`insurer--${insurers.length}`, { 'insurer-recommended': insurer.key === recommendedInsurer }]"
                        v-for="insurer in insurers"
                        :key="insurer.key"
                      >
                        <div class="recommendation-frame"></div>
                        <div
                          v-if="
                            $store.state.offer?.offer?.insuredSumOption1 &&
                            $store.state.offer?.offer?.altProducts?.[insurer.key]?.[0]?.gross
                          "
                          class="insured-sum-options__option"
                        >
                          <div class="insured-sum-options__sum">
                            Versicherungssumme
                            {{ $store.state.offer?.offer?.altProducts?.[insurer.key]?.[0].insuredSum | currency(0) }}:
                          </div>
                          <div class="insured-sum-options__gross">
                            {{ $store.state.offer?.offer?.altProducts?.[insurer.key]?.[0].gross | currency(2) }}
                          </div>
                        </div>
                        <div v-else class="insured-sum-options__option" />

                        <div
                          v-if="
                            $store.state.offer?.offer?.insuredSumOption2 &&
                            $store.state.offer?.offer?.altProducts?.[insurer.key]?.[1]?.gross
                          "
                          class="insured-sum-options__option"
                        >
                          <div class="insured-sum-options__sum">
                            Versicherungssumme
                            {{ $store.state.offer?.offer?.altProducts?.[insurer.key]?.[1].insuredSum | currency(0) }}:
                          </div>
                          <div class="insured-sum-options__gross">
                            {{ $store.state.offer?.offer?.altProducts?.[insurer.key]?.[1].gross | currency(2) }}
                          </div>
                        </div>
                        <div v-else class="insured-sum-options__option" />
                      </div>
                    </div>
                  </div>
                  <!-- start props here -->
                  <TableStatistics
                    ref="tableStatisticsComponent"
                    :insurers="!isPrintMode ? insurers : printInsurers"
                    :isLoading="isLoading"
                    :getPriceToolTip="getPriceToolTip"
                    :isOffer="isOffer"
                    :isAffiliate="isAffiliate"
                    :mode="mode"
                    :isPricePerformWinner="isPricePerformWinner"
                    :isInsurerPricePerformWinner="isInsurerPricePerformWinner"
                    class="statistics-table d-print-none"
                    :class="{ 'score-visible': isScoreVisible }"
                  >
                  </TableStatistics>
                </div>
              </div>
              <PropertyGrid
                v-if="!isQuestionsComparisonView"
                ref="propertyGridComponent"
                class="d-print-none"
                :insurers="!isPrintMode ? insurers : printInsurers"
                :isAvailable="isAvailable"
                :isOptional="isOptional"
                :isSelected="isSelected"
                :compressedMode="compressedMode"
                :getNote="getNote"
                :optionalChanged="optionalChanged"
                :inputs="inputs"
                :insuranceProperties="insuranceProperties2_0"
                :mode="mode"
                :sortBy="sortBy"
                :isOffer="isOffer"
              />
              <QuestionCategories
                v-if="isQuestionsComparisonView"
                class="d-print-none"
                :affiliateBroker="affiliateBroker"
                :inputsData="inputs"
                :insurers="insurers"
                :mode="mode"
                :offerSelection="offerSelection"
              />
              <table class="print-insurers-wrap d-none d-print-block">
                <thead>
                  <InsurersPrint
                    ref="insurersPrintComponent"
                    class="d-none d-print-block"
                    :key="'insurers-print-' + insurers.length"
                    :insurers="!isPrintMode ? insurers : printInsurers"
                    :synaxon="synaxon"
                    :checkoutButtonIsVisible="checkoutButtonIsVisible"
                    :affiliateBroker="affiliateBroker"
                    :order1="order1Mixin"
                    :order2="order2Mixin"
                    :isBroker="isBroker"
                    :mode="mode"
                    :offerSelection="offerSelection"
                    :maxOffers="maxOffers"
                    @updateOfferSelection="updateOfferSelection"
                    @selectInsurer="selectInsurer"
                    :getBanner="getBanner"
                    :getBannerClass="getBannerClass"
                    :isOffer="isOffer"
                    :isInsurerPricePerformWinner="isInsurerPricePerformWinner"
                    :getPriceToolTip="getPriceToolTip"
                    :isPricePerformWinner="isPricePerformWinner"
                  >
                  </InsurersPrint>
                </thead>

                <tbody>
                  <TableStatisticsPrint
                    ref="tableStatisticsPrintComponent"
                    :insurers="!isPrintMode ? insurers : printInsurers"
                    :isLoading="isLoading"
                    :getPriceToolTip="getPriceToolTip"
                    :is-offer="isOffer"
                    :mode="mode"
                    :isPricePerformWinner="isPricePerformWinner"
                    :isInsurerPricePerformWinner="isInsurerPricePerformWinner"
                    class="statistics-table d-none d-print-block printable-properties"
                  >
                  </TableStatisticsPrint>
                  <PropertyTablePrint
                    ref="propertyTablePrintComponent"
                    class="d-none d-print-block printable-properties"
                    :insurers="!isPrintMode ? insurers : printInsurers"
                    :isAvailable="isAvailable"
                    :isOptional="isOptional"
                    :isSelected="isSelected"
                    :compressedMode="compressedMode"
                    :getNote="getNote"
                    :optionalChanged="optionalChanged"
                    :inputs="inputs"
                    :isOffer="isOffer"
                    :insuranceProperties="insuranceProperties2_0"
                    :mode="mode"
                  ></PropertyTablePrint>
                </tbody>
              </table>

              <BannersPrint
                class="d-none d-print-block page-break-after"
                :key="'insurers-print-' + insurers.length"
                :insurers="!isPrintMode ? insurers : printInsurers"
                :synaxon="synaxon"
                :checkoutButtonIsVisible="checkoutButtonIsVisible"
                :affiliateBroker="affiliateBroker"
                :order1="order1Mixin"
                :order2="order2Mixin"
                :isBroker="isBroker"
                :mode="mode"
                :offerSelection="offerSelection"
                :maxOffers="maxOffers"
                @updateOfferSelection="updateOfferSelection"
                @selectInsurer="selectInsurer"
                :getBanner="getBanner"
                :getBannerClass="getBannerClass"
                :isOffer="isOffer"
              ></BannersPrint>
              <template v-if="!offerWithRecommendation">
                <div class="print-insurers-headline d-none d-print-block">
                  Risikofragen
                </div>
                <InsurersPrint
                  ref="insurersPrintComponent"
                  class="d-none d-print-block print-insurers-margin"
                  :key="'insurers-print--' + insurers.length"
                  :insurers="!isPrintMode ? insurers : printInsurers"
                  :synaxon="synaxon"
                  :checkoutButtonIsVisible="checkoutButtonIsVisible"
                  :affiliateBroker="affiliateBroker"
                  :order1="order1Mixin"
                  :order2="order2Mixin"
                  :isBroker="isBroker"
                  :mode="mode"
                  :offerSelection="offerSelection"
                  :maxOffers="maxOffers"
                  @updateOfferSelection="updateOfferSelection"
                  @selectInsurer="selectInsurer"
                  :getBanner="getBanner"
                  :getBannerClass="getBannerClass"
                  :isOffer="isOffer"
                  :isInsurerPricePerformWinner="isInsurerPricePerformWinner"
                  :getPriceToolTip="getPriceToolTip"
                  :isPricePerformWinner="isPricePerformWinner"
                  :isQuestionsHeader="true"
                >
                </InsurersPrint>
                <QuestionCategoriesPrint
                  class="d-none d-print-flex"
                  :affiliateBroker="affiliateBroker"
                  :inputsData="inputs"
                  :insurers="insurers"
                  :mode="mode"
                  :offerSelection="offerSelection"
                  :offerModules="inputs?.selectedModules"
                />
                <div v-if="application_name === 'portal-2' || isOffer">
                  <FooterTariffsInfo :isOffer="isOffer" />
                </div>
              </template>
              <input type="submit" id="step_submit" v-if="!isOffer" />
            </form>
            <slot />
          </div>
        </tr>
      </tbody>
    </table>


    <modal class="offer-modal" :closeable="true" size="sm" v-if="showOfferInsurerModal" @close="showOfferInsurerModal = false">
      <div slot="body">
        <div class="mb-2">Sie können bis zu {{ maxOffers }} Versicherer für den Marktvergleich im Angebot auswählen.</div>
        <div>
          <strong>Ausgewählt: {{ offerSelection.length }}</strong>
        </div>
      </div>
      <div slot="footer" class="d-flex justify-content-between footer-btns">
        <button class="btn btn--secondary" type="button" @click="createOffer(true)" slot="footer">Fortfahren</button>
        <button class="btn btn-style back-zu" type="button" @click="showOfferInsurerModal = false" slot="footer">
          Zus&auml;tzliche Versicherer ausw&auml;hlen
        </button>
      </div>
    </modal>
    <!-- TODO: modal in single component -->
    <modal class="offer-modal" :closeable="true" v-if="showRegModal" @close="showRegModal = false">
      <h3 slot="header" v-if="!showAffiliateSuccess">
        <span v-if="isAffiliate">Angebot anfordern</span>
        <span v-else>Erstellen Sie ein Angebot</span>
      </h3>
      <div slot="body" v-if="showAffiliateSuccess">
        <div class="col-12">
          <h2 class="text-center">Vielen Dank!</h2>
          <p class="text-center">
            Sie erhalten umgehend per E-Mail ein unverbindliches Angebot, das Ihren individuellen Anforderungen entspricht.
          </p>
        </div>
        <div class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4 col-lg-2 offset-lg-5">
          <img src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/success_icon.svg" />
        </div>
      </div>
      <div slot="body" v-if="!showAffiliateSuccess">
        <div class="table-wrapper">
          <table class="table" v-if="!isAffiliate">
            <thead>
              <tr>
                <th>Versicherer im Vergleich</th>
                <th>Als Empfehlung kennzeichnen</th>
                <th>Entfernen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="insurerKey in offerSelection" :key="insurerKey">
                <td>{{ getInsurerName(insurerKey) }}</td>
                <td class="text-center">
                  <div class="custom-control custom-radio">
                    <input
                      class="custom-control-input"
                      type="radio"
                      :id="`radio-${insurerKey}`"
                      v-model="inputs.insurer"
                      :value="insurerKey"
                      name="selectedInsurer"
                    />
                    <label class="custom-control-label" :for="`radio-${insurerKey}`"></label>
                  </div>
                </td>
                <td>
                  <button class="btn btn-icon" type="button" @click="removeSelectedInsurer(insurerKey)">
                    <icon name="delete" color="dark-grey"></icon>
                  </button>
                </td>
              </tr>
              <tr>
                <td>keine Empfehlung</td>
                <td class="text-center" colspan="1">
                  <div class="custom-control custom-radio">
                    <input
                      class="custom-control-input"
                      type="radio"
                      id="radio-no-recommendation"
                      v-model="inputs.insurer"
                      value="no-recommendation"
                      name="selectedInsurer"
                    />
                    <label class="custom-control-label" for="radio-no-recommendation"></label>
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <SwitchCheckbox v-if="!isAffiliate" v-model="offer.showRating" class="mb-3"><b>Rating im Angebot anzeigen</b> </SwitchCheckbox>
          <SwitchCheckbox
            v-if="!isAffiliate"
            v-model="showPricePerformWinner"
            :disabled="!offerSelection.includes(pricePerformWinner.key)"
            class="mb-3"
            ><b>Preis-Leistungs-Sieger im Angebot anzeigen</b></SwitchCheckbox
          >
        </div>
        <div class="input-group mb-3" v-if="!isAffiliate">
          <select
            class="form-control"
            name="additionalInsurer"
            id="additionalInsurer"
            :disabled="offerSelection.length <= 1"
            v-model="offer.sortBy"
          >

            <option  v-for="o in sortByOptions" :value="o.value" :key="o.value">
              {{ o.label }}
            </option>
          </select>
          <div class="input-group-append">
            <button
              class="btn btn--default"
              type="button"
              @click="addAdditionalInsurer()"
              :disabled="offerSelection.length >= 4 || !additionalInsurer"
            >
              Gew&uuml;nschte Sortierung
            </button>
          </div>
        </div>

        <div class="input-group mb-5" v-if="!isAffiliate">
          <select
            class="form-control"
            name="additionalInsurer"
            id="additionalInsurer"
            :disabled="offerSelection.length >= 4"
            v-model="additionalInsurer"
          >
            <option :value="insurer.key" v-for="insurer in additionalInsurers" :key="insurer.key">
              {{ insurer.label }}
            </option>
          </select>
          <div class="input-group-append">
            <button
              class="btn btn--secondary"
              type="button"
              @click="addAdditionalInsurer()"
              :disabled="offerSelection.length >= 4 || !additionalInsurer"
            >
              Weiteren Versicherer hinzufügen
              <span v-if="insurers.length > maxOffers">(max. {{ maxOffers }})</span>
            </button>
          </div>
        </div>
        <div class="form-group" :class="getFieldClass('company')">
          <label for="company">Firma*</label>
          <input class="form-control" type="text" id="company" name="company" v-model="offer.company" v-validate="'required'" />
          <small class="error-message">Geben Sie mindestens den Firmen- oder Nachnamen ein.</small>
        </div>
        <div class="form-group" :class="getFieldClass('salutation')">
          <label for="salutation">Anrede</label>
          <select class="form-control" name="salutation" id="salutation" v-model="offer.salutation">
            <option selected disabled hidden value="">Wählen</option>
            <option value="Frau">Frau</option>
            <option value="Herr">Herr</option>
          </select>
        </div>
        <div class="form-group" :class="getFieldClass('firstname')">
          <label for="firstname">Vorname</label>
          <input class="form-control" type="text" id="firstname" name="firstname" v-model="offer.firstname" />
        </div>
        <div class="form-group" :class="getFieldClass('lastname')">
          <label for="lastname">Nachname *</label>
          <input class="form-control" type="text" id="lastname" name="lastname" v-model="offer.lastname" v-validate="'required'" />
          <small class="error-message">Geben Sie mindestens den Firmen- oder Nachnamen ein.</small>
        </div>
        <div class="form-group" :class="getFieldClass('email')" v-if="isAffiliate">
          <label for="lastname">E-Mail *</label>
          <input class="form-control" type="text" id="email" name="email" v-model="offer.email" v-validate="'required|email'" />
        </div>
        <PhoneForm
          class="form-relative"
          name="Mobiltelefon"
          v-model="offer.phone"
          v-validate="this.affiliateBroker && this.affiliateBroker.agency === 'synaxon' ? 'required|min:4' : 'min:4'"
          :required="this.affiliateBroker && this.affiliateBroker.agency === 'synaxon' ? true : false"
          @numberChange="updatePhoneNumber"
        />
        <div class="custom-control custom-checkbox" :class="{ 'is-danger': submitted && !offer.dataPrivacyCheck }" v-if="isAffiliate">
          <input
            type="checkbox"
            class="custom-control-input"
            id="dataPrivacyCheck"
            name="dataPrivacyCheck"
            v-model="offer.dataPrivacyCheck"
          />
          <label class="custom-control-label text-muted check-label legal-note" for="dataPrivacyCheck">
            Ich bin mit den
            <a href="https://www.cyberdirekt.de/datenschutzhinweise" target="_blank">Datenschutzhinweisen von CyberDirekt</a>
            <template v-if="this.affiliateBroker && this.affiliateBroker.agency === 'synaxon'">
              und der
              <a href="https://pma-assekuranzmakler.de/datenschutzerklaerung/" target="_blank">Assekuranzmakler GmbH</a>
            </template>
            einverstanden.
          </label>
        </div>
        <div class="custom-control custom-checkbox" :class="{ 'is-danger': submitted && !offer.contactBrokerCheck }" v-if="isAffiliate">
          <input
            type="checkbox"
            class="custom-control-input"
            id="contactBrokerCheck"
            name="contactBrokerCheck"
            v-model="offer.contactBrokerCheck"
          />
          <label class="custom-control-label text-muted check-label legal-note" for="contactBrokerCheck">
            Ich möchte für eine Beratung von {{ affiliateBroker.firstname }}
            {{ affiliateBroker.lastname }}
            kontaktiert werden.
          </label>
        </div>
        <div class="form-group" :class="getFieldClass('salutationOverwrite')" v-if="!isAffiliate">
          <label for="salutation">Anrede überschreiben</label>
          <input class="form-control" type="text" id="salutationOverwrite" name="salutationOverwrite" v-model="offer.salutationOverwrite" />
          <small>Leer lassen für Standardanrede</small>
        </div>
        <div class="form-group" v-if="!isAffiliate">
          <label>Angezeigte Anrede</label>
          <input class="form-control" type="text" readonly :value="getSalutation()" />
        </div>
        <div class="form-group" v-if="!isAffiliate">
          <label for="reason">Wahl des Versicherers</label>
          <textarea class="form-control" name="reason" id="reason" rows="5" v-model="offer.reason"></textarea>
        </div>
        <div class="form-group" v-if="!isAffiliate">
          <label for="comment">Kommentar</label>
          <textarea class="form-control" name="comment" id="comment" rows="5" v-model="offer.comment"></textarea>
        </div>
        <div class="form-group" v-if="!isAffiliate">
          <label for="description"
            >Interne Notiz
            <small>(Kann nicht vom Kunden eingesehen werden)</small>
          </label>
          <textarea class="form-control" name="description" id="description" rows="5" v-model="offer.description"></textarea>
        </div>
      </div>
      <div slot="footer" v-if="!showAffiliateSuccess" class="footer-btns">
        <button class="btn btn--default" type="button" @click="showRegModal = false" slot="footer">Abbrechen</button>
        <button class="btn btn-secondary" type="button" :disabled="errors.any()" @click="saveOffer" slot="footer">
          <span v-if="isAffiliate">Angebot anfordern</span>
          <span v-else>Angebot speichern</span>
        </button>
      </div>
    </modal>
    <modal class="offer-modal" :closeable="true" v-if="showSortInfoModal" @close="showSortInfoModal = false">
      <h3 slot="header" class="w-100">Erläuterung zur Sortierung</h3>
      <div slot="body">
        <p v-for="option in sortByOptions" :key="'sort-opt-' + option.value">
          <b>{{ option.label }}:</b> {{ option.info }}
        </p>
      </div>
    </modal>
    <modal class="offer-modal" :closeable="true" v-if="showOptModuleInfoModal" @close="showOptModuleInfoModal = false">
      <h3 slot="header" class="w-100">Erläuterung zu den optionalen Bausteinen</h3>
      <div slot="body">
        <p>
          Die einzelnen Anbieter haben für ihre Tarife hinsichtlich optionaler Bausteine verschiedene Herangehensweisen. Das Ziel des
          CyberDirekt Marktvergleichs ist es, diese Komplexität zu reduzieren und leicht vergleichbar zu machen, aber trotzdem noch die
          Flexibilität und Struktur jedes einzelnen Tarifs zu erhalten. Manche Tarife bieten gar keine optionalen Bausteine an, während
          andere Tarife über verschiedene, jeweils unabhängig voneinander stehende optionale Bausteine verfügen. Es gibt noch keinen
          Standard, welche Leistungen optional an- beziehungsweise abwählbar sind, ist noch sehr unterschiedlich. Zum Teil werden auch
          bestimmte Tarifmerkmale zu einem optionalen Baustein (z.B. Cyber-Diebstahl/Cyber-Betrug) oder zu einem kompletten Bausteinpaket
          kombiniert. Sollte dies der Fall sein, werden bei An-/Abwahl eines Tarifmerkmales gleichzeitig die anderen kombinierten
          Tarifmerkmale an- beziehungsweise abgewählt. Durch An-/Abwahl (Anwahl: Mausklick auf den weißen Kreis des jeweiligen Merkmals;
          Abwahl: Mausklick auf den hellblau ausgefüllten Kreis) der optionalen Bausteine eines Tarifs ändert sich entsprechend die Prämie
          und das Rating wird automatisch angepasst.
        </p>
        <p>
          <b>Produktstandard:</b> Die optionalen Bausteine sind entsprechend der gewünschten Auswahl des Anbieters an-beziehungsweise
          abgewählt. Durch An-/Abwahl einzelner optionaler Bausteine kann der jeweilige Tarif jederzeit angepasst werden.
        </p>

        <p>
          <b>Alle angewählt:</b> Alle optionalen Bausteine sind angewählt. Durch Abwahl einzelner optionaler Bausteine kann der jeweilige
          Tarif jederzeit angepasst werden.
        </p>

        <p>
          <b>Alle abgewählt:</b> Alle optionalen Bausteine sind abgewählt. Durch Anwahl einzelner optionaler Bausteine kann der jeweilige
          Tarif jederzeit angepasst werden.
        </p>
      </div>
    </modal>

    <modal :closeable="true" v-if="modalBody" @close="modalBody=''" class="insurer__modal">
      <h3 slot="header" v-if="isVisibleModalHeader">
        Erläuterung zum Sideletter
      </h3>

      <div slot="body" v-html="modalBody" />
    </modal>

    <div class="loading-overlay" v-if="isLoadingOverlay" :class="{ 'loading-overlay--in': isLoadingOverlay }">
      <LoaderComponent></LoaderComponent>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import eventBus from '@/components/shared/EventBus.js'
import Icon from '../Icon.vue'
import sticky from '../sticky'
import ConditionCheck from '../ConditionCheck'
import jwt from 'jsonwebtoken'
import { jwtDecode } from 'jwt-decode' // for >18 node projects
import moment from 'moment'
import Modal from '../Modal'
import InfoPopup from './InfoPopup'
import store from '@/store/index'
import StringHelper from '../StringHelper'
import { get as getValue, debounce } from 'lodash'
import PhoneForm from './shared/PhoneForm'
import OfferDetailsCard from './offer/DetailsCard'
import ButtonComponent from './calculator-v2.0/ui/ButtonComponent.vue'
import SelectComponent from './calculator-v2.0/ui/SelectComponent.vue'
import Card from './calculator-v2.0/block/Card.vue'
import Insurers from './calculator-v2.0/parts/Insurers'
import InsurersPrint from './calculator-v2.0/parts/InsurersPrint'
import FooterTariffsInfo from './calculator-v2.0/parts/FooterTariffsInfo'
import BannersPrint from './calculator-v2.0/parts/BannersPrint'
import TableStatistics from './calculator-v2.0/parts/TableStatistics'
import TableStatisticsPrint from './calculator-v2.0/parts/TableStatisticsPrint.vue'
import PropertyGrid from './calculator-v2.0/parts/PropertyGrid.vue'
import PropertyTablePrint from './calculator-v2.0/parts/PropertyTablePrint.vue'
import LoaderComponent from './shared/LoaderComponent.vue'
import NewFeature from './calculator-v2.0/parts/NewFeature.vue'
import isStepAllowed from '../mixins/isStepAllowed.js'
import bannersLogic from '../mixins/banners.js'
import multiModules from '../mixins/multiModules.js'
import computedInsurers from '../mixins/computedInsurers.js'
import defaultSelection from '../mixins/defaultSelection.js'
import InsurerSorting from '../../api/insurer-sorting'
import QuestionCategories from './calculator-v2.0/parts/QuestionCategories/Index.vue'
import QuestionCategoriesPrint from './calculator-v2.0/parts/QuestionCategories/Print.vue'
import insurerSharedMethods from "../mixins/insurerSharedMethods.js";


moment.locale('de')
import SwitchCheckbox from './shared/SwitchCheckbox.vue'

const jwtDecodeFunc = jwtDecode
const ENCODE_SECRET =
  'Ewr8BVlLbYtBIgSV1JSyHDSxo2ViC63EXMA376EzktRB7XrVUZ1bYKXPIv9IqhnLIiTLmS4PBPIkNzMyBLzZBgDw9h27BDEcmJKFNWlW29mXcvnx2bVPMm41q07RZE3X8ncetKTECna0'

export default {
  name: 'Produktvergleich',
  mixins: [
    isStepAllowed,
    multiModules,
    computedInsurers,
    bannersLogic,
    defaultSelection,
    insurerSharedMethods
  ],
  components: {
    Icon,
    Modal,
    PhoneForm,
    ButtonComponent,
    Card,
    SelectComponent,
    Insurers,
    InsurersPrint,
    BannersPrint,
    TableStatistics,
    TableStatisticsPrint,
    PropertyGrid,
    PropertyTablePrint,
    SwitchCheckbox,
    OfferDetailsCard,
    QuestionCategories,
    QuestionCategoriesPrint,
    NewFeature,
    LoaderComponent,
    InfoPopup,
    FooterTariffsInfo
  },
  directives: {
    sticky,
  },
  data() {
    return {
      activeSlide: 0,
      //
      isVisibleModalHeader: false,
      modalBody: "",
      showRegModal: false,
      showSortInfoModal: false,
      showOptModuleInfoModal: false,
      showShadow: false,
      shadowColWidth: '402px',
      showAffiliateSuccess: false,
      submitted: false,
      additionalInsurer: null,
      offer: {
        company: '',
        firstname: '',
        lastname: '',
        salutation: 'Herr',
        salutationOverwrite: '',
        reason: StringHelper.get(this.$store, 'OFFER_REASON_NO_RECOMMENDATION', true),
        comment: StringHelper.get(this.$store, 'OFFER_COMMENT_NO_RECOMMENDATION', true),
        description: '',
        phone: '',
        showRating: true,
        showPricePerformWinner: true,
        sortBy: 'score',
      },
      compressedMode: false,
      insuredSum: 0,
      groups: [],
      isLoading: false,
      isLoadingOverlay: false,
      isQuestionsComparisonView: false,
      offerSelection: [],
      showOfferInsurerModal: false,
      riskQuestionMapping: {
        'diebstahl-/-verlust-von-hardware': 'mobile-network',
        'betriebsunterbrechung-durch-externen-dienstleister-/-cloudanbieter': 'cloud-services',
        bringyourowndevice: 'byod',
        epayment: 'creditcards',
        'haftpflicht-fur-vertraglichen-schadenersatz': 'schadenersatzansprueche',
      },
      moduleModes: [
        {
          value: 'default',
          label: 'Produktstandard',
        },
        {
          value: 'all',
          label: 'Alle angewählt',
        },
        {
          value: 'none',
          label: 'Alle abgewählt',
        },
      ],
      sortBy: 'score',
      stepData: this.step || null,
      isTblHeaderSticky: false,
      noInsurersText: StringHelper.get(this.$store, 'STEP_2_NO_INSURERS_TEXT', false),
      paymentPeriods: [
        {
          value: 'yearly',
          label: 'Jährlich',
        },
        {
          value: 'halfyearly',
          label: 'Halbjährlich',
        },
        {
          value: 'quarterly',
          label: 'Vierteljährlich',
        },
        {
          value: 'monthly',
          label: 'Monatlich',
        },
      ],
      recommendedInsurer: this.$store.state.offer?.offer?.insurer,
    }
  },
  head() {
    return {
      meta: [
        {
          p: 'og:image',
          c: 'https://www.cyberdirekt.de/static/brand-assets/web/risikoaufklaerung_web.jpg',
        },
      ],
    }
  },
  watch: {
    offerSelection(value) {
      this.$store.commit('SET_OFFER_SELECTION', value)
    },
    insurerSortingKey() {
      this.sortBy = this.insurerSortingKey
    },
    selectedModulesType() {
      this.selectModules(this.selectedModulesType)
    },
    'inputs.insuredSum'(a, b) {
      if (a !== b) {
        eventBus.$emit('isLoading', true)
        this.isLoading = true
        this.defaultSelection()
        this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
          eventBus.$emit('isLoading', false)
          this.isLoading = false
        })
      }
    },
    'inputs.deductible'(a, b) {
      if (a !== b) {
        eventBus.$emit('isLoading', true)
        this.isLoading = true
        this.defaultSelection()
        this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
          eventBus.$emit('isLoading', false)
          this.isLoading = false
        })
      }
    },
    'inputs.paymentPeriod'(a, b) {
      if (a !== b) {
        eventBus.$emit('isLoading', true)
        this.isLoading = true
        this.defaultSelection()
        this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
          eventBus.$emit('isLoading', false)
          this.isLoading = false
        })
      }
    },
    'inputs.selectedModules': {
      handler() {
        eventBus.$emit('isLoading', true)
        this.isLoading = true
        // I Don't see any reason we need defultSelection() here.
        // this.defaultSelection()
        this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
          eventBus.$emit('isLoading', false)
          this.isLoading = false
        })
      },
      deep: true,
    },
    'inputs.insurer'(insurerSelection) {
      if (insurerSelection === 'no-recommendation') {
        this.offer.reason = StringHelper.get(this.$store, 'OFFER_REASON_NO_RECOMMENDATION', true)
        this.offer.comment = StringHelper.get(this.$store, 'OFFER_COMMENT_NO_RECOMMENDATION', true)
      } else {
        this.offer.reason = StringHelper.get(this.$store, 'OFFER_REASON', true, { insurer: this.getInsurerName(insurerSelection) })
        this.offer.comment = StringHelper.get(this.$store, 'OFFER_COMMENT', true)
      }
    },
    'inputs.meta': {
      handler(meta) {
        // TODO: remove victor-architekten-ingenieure
        if (
          meta &&
          meta['victor-architekten-ingenieure'] &&
          meta['victor-architekten-ingenieure']['betrieb-von-bimsystemen'] &&
          meta['victor-architekten-ingenieure']['betrieb-von-bimsystemen'] === 'cost_50000001'
        ) {
          eventBus.$emit('alertUser', {
            type: 'error',
            title: 'Aufgrund des Risikoprofils kann kein standardisierter Versicherungsschutz angeboten werden.',
            message: '',
            customCloseBtnText: 'OK',
            customCloseBtnClass: 'btn btn-primary ',
          })
          this.inputs.meta['victor-architekten-ingenieure']['betrieb-von-bimsystemen'] = 'cost_0'
        } else {
          eventBus.$emit('isLoading', true)
          this.isLoading = true
          this.$store.dispatch('CALCULATE_CYBER_PRICES').then(() => {
            eventBus.$emit('isLoading', false)
            this.isLoading = false
          })
        }
      },
      deep: true,
    },
    // this watcher has to stay at the last position, because all the other watchers are triggering async
    // processes that trigger isLoading and block the pop-up with `no insurers found` until everything is really loaded.
    insurers(newValue) {
      debounce(function () {
        eventBus.$emit('insurerCount', this.insurers.length)
      }, 300)

      if (newValue.length === 0 && !this.isLoading) {
        eventBus.$emit('alertUser', {
          type: 'info',
          title: null,
          message: this.noInsurersText,
          customCloseBtnText: 'OK',
          customCloseBtnClass: 'btn btn-primary',
        })
      }
      // this.forceUpdateInsurers()
    },
    isLoading(newValue) {
      if (newValue) {
        this.isLoadingOverlay = true
      } else if (newValue === false && this.insurers.length === 0) {
        this.isLoadingOverlay = false
        eventBus.$emit('alertUser', {
          type: 'info',
          title: null,
          message: this.noInsurersText,
          customCloseBtnText: 'OK',
          customCloseBtnClass: 'btn btn-primary',
        })
      } else {
        this.isLoadingOverlay = false
      }
    },

    // sortBy () {
    // //bad solution, never stops loading in some cases
    //   this.$store.commit('SET_CHECKOUT_LOADING_OVERLAY', true)
    // }
  },
  computed: {
    ...mapGetters([
      'isBroker',
      'isWWW',
      'sharablePayload',
      'affiliateBroker',
      'style',
      'isInitialLoadSortingRefresh',
      'insurersWithMultiModules',
      'insurerSortingKey',
      'filteredInsurers',
    ]),
    application_name() {
      return this.$store.state.application_name
    },
    pricePerformWinner() {
      return InsurerSorting.getSortedInsurers([...this.filteredInsurers], 'price-score-ratio')[0]
    },
    showPricePerformWinner: {
      get() {
        if (this.offerSelection?.includes(this.pricePerformWinner.key)) {
          return this.offer.showPricePerformWinner
        } else {
          return false
        }
      },
      set(value) {
        if (this.offerSelection?.includes(this.pricePerformWinner.key)) {
          this.offer.showPricePerformWinner = value
        }
      },
    },
    sortByOptions() {
      let options = [
        {
          value: 'abc',
          label: 'Alphabetisch',
          info: 'Alphabetische Sortierung basierend auf dem Namen des Anbieters von AXA bis Zurich.',
        },
        {
          value: 'questions-amount',
          label: 'Anzahl Risikofragen',
          info: 'Für die Anordnung der Tarife wird die Jahresprämie der jeweils ausgewählten Produktvariante durch die Ratingwertung geteilt, d.h. es wird berechnet, wie viel € ein Ratingpunkt kostet. Je besser das Verhältnis, desto weiter links ist der Tarif angeordnet.',
        },
        {
          value: 'score',
          label: 'Leistung',
          info: 'Die Tarife werden basierend auf dem CyberDirekt Rating angeordnet. Absteigend von links mit dem besten verfügbaren Tarif. Die Sortierung basiert auf der jeweils ausgewählten Produktvariante des Tarifs. Durch An-/Abwahl möglicher optionaler Bausteine kann sich die Reihenfolge ändern. Bei der Abwahl eines optionalen Bausteins sinkt in der Regel das Rating, bei der Zuwahl steigt es entsprechend.',
        },
        {
          value: 'price',
          label: 'Preis',
          info: 'Die Anordnung basiert auf der Jahresprämie des Tarifs in der jeweils ausgewählten Produktvariante. Absteigend von links mit dem günstigsten Tarif. Durch An-/Abwahl möglicher optionaler Bausteine kann sich die Reihenfolge ändern. Bei der Abwahl eines optionalen Bausteins sinkt in der Regel der Preis, bei der Zuwahl steigt dieser entsprechend.',
        },
        {
          value: 'price-score-ratio',
          label: 'Preis/Leistung',
          info: 'Für die Anordnung der Tarife wird die Jahresprämie der jeweils ausgewählten Produktvariante durch die Ratingwertung geteilt, d.h. es wird berechnet, wie viel € ein Ratingpunkt kostet. Je besser das Verhältnis, desto weiter links ist der Tarif angeordnet.',
        },

      ]

      if ((this.isAffiliate || this.isWWW) && !this.isOffer) {
        options = options.filter((o) => o.value !== 'score' && o.value !== 'price-score-ratio')
      }

      return options
    },
    insuranceProperties2_0() {
      return this.$store.state.checkout.application.insuranceProperties2_0
    },
    isScoreVisible: {
      get() {
        return this.$store.state.checkout.application.isScoreVisible
      },
      set(newValue) {
        this.$store.commit('SET_SCORE_VISIBILITY', newValue)
      },
    },
    isAffiliate() {
      return this.style === 'affiliate'
    },
    isPrintMode() {
      return this.$store.state.checkout.application.isPrintMode
    },
    printInsurers() {
      return this.insurers.slice(0, 4)
    },
    offerText() {
      return this.isAffiliate ? 'Angebot anfordern' : 'Angebot erstellen'
    },
    // nameCheck() {
    //   return !this.offer.lastname && !this.offer.company ? 'required' : ''
    // },
    checkoutButtonIsVisible() {
      return this.offerSelection.length < 2 && !this.isOffer
    },
    additionalInsurers() {
      return this.insurers.filter((insurer) => this.offerSelection.indexOf(insurer.key) === -1)
    },
    revenueRange() {
      return this.$store.getters.revenueRange
    },
    possibleDeductibles2() {
      return this.$store.getters.possibleDeductibles2
    },
    possibleDeductibles() {
      return this.$store.getters.possibleDeductibles(this.insurers)
    },
    insuredSums() {
      return this.$store.getters.possibleInsuredSums(this.insurers)
    },
    stickySettings() {
      // no sticky for IE11
      const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
      let offset = 0

      if (this.isOffer) {
        offset = 0
      } else if (this.isBroker && this.isAffiliate) {
        offset = 126
      } else if (this.isWWW && this.$isMobile) {
        offset = 0
      } else if (this.isWWW) {
        offset = 128
      } else if (this.isBroker) {
        offset = 129
      }

      return {
        zIndex: 800,
        stickyTop: offset,
        isIE11,
      }
    },
    insurerPrices() {
      return this.$store.state.checkout.application.prices
    },
    inputs() {
      return this.$store.state.checkout.application.inputs
    },
    steps() {
      return this.$store.getters.filteredSteps()
    },
    insuranceProperties() {
      return this.$store.getters.filteredInsuranceProperties
    },
    isOffer() {
      return this.mode === 'offer'
    },
    riskAnswers() {
      return this.$store.state.riskQuestions2 ? this.$store.state.riskQuestions2 : []
    },
    shareLink() {
      const insurerQuery = this.$route.query ? this.$route.query.insurer : []
      let CLIENT_BASE = process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE
      CLIENT_BASE = CLIENT_BASE.replace(/\/?$/, '/')
      let selectedInsurers = typeof insurerQuery === 'string' ? [insurerQuery] : insurerQuery
      if (
        this.isOffer &&
        this.$store.state.offer &&
        this.$store.state.offer.offer &&
        this.$store.state.offer.offer.selection &&
        this.$store.state.offer.offer.selection.length
      ) {
        selectedInsurers = this.$store.state.offer.offer.selection
      }
      let insurerUrl = selectedInsurers && selectedInsurers.length ? `&insurer=${selectedInsurers.join('&insurer=')}` : ''
      const encoded = this._encodeSharedLink(this.sharablePayload)

      return `${CLIENT_BASE}versicherung/2/produktvergleich?shared=${encoded}${insurerUrl}`
    },
    // downloadLink() {
    //   const API_BASE = process.env.API_BASE || process.env.VUE_APP_API_BASE
    //   let htmlSourceUrl = this.isOffer ? window.location.href : this.shareLink
    //   return `${API_BASE}/pdf/generate-pdf?url=${encodeURIComponent(htmlSourceUrl)}&print=true`
    // },
    synaxon() {
      return this.affiliateBroker && this.affiliateBroker.agency === 'synaxon'
        ? {
            tooltip: {
              trigger: 'Exklusiv für Kunden von einsnulleins und SYNAXON*',
              text: ``,
            },
          }
        : null
    },
    selectedModulesType: {
      get() {
        return this.$store.state.checkout.application.selectedModulesType
      },
      set(type = 'default') {
        switch (type) {
          case 'default':
            this.$store.commit('DEFAULT_SELECT_MODULES')
            break
          case 'none':
            this.$store.commit('DESELECT_ALL_MODULE')
            break
          case 'all':
            this.$store.commit('SELECT_ALL_MODULES')
            break
        }
      },
    },
    isInitialDataLoaded() {
      return this.$store.state.checkout.application.isInitialDataLoaded
    },
    allowRatingsSwitch() {
      return this.isOffer ? this.offerSettings.showRating : this.isAffiliate ? false : this.isWWW ? false : true
    },
    modeStyle() {
      return this.$store.state.checkout.application.style
    },
    offerWithRecommendation() {
      return Boolean(
          this.$store.state.offer?.offer?.insurer !== null &&
          this.$store.state.offer?.offer?.insurer !== undefined &&
          this.$store.state.offer?.offer?.insurer !== 'no-recommendation'
        )
    },
  },
  props: {
    step: undefined,
    mode: {
      type: String,
      default() {
        return this.$store.state.checkout.application.mode
      },
    },
    offerSettings: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  mounted: async function () {
    const { shared } = this.$route.query
    const productStep = this.$store.state.checkout.application.steps.find((step) => step.slug === 'produktauswahl')
    let check = !this.isBroker || this.isAffiliate ? 'produktauswahl' : 'produktvergleich'
    this.stepData = this.steps.find((step) => step.slug === check)
    document.querySelector('html').classList.add('product-table')
    document.body.classList.add(
      'printable-css',
      'body-offer',
      `${this.mode || this.modeStyle}-mode`,
      this.isAffiliate ? 'is-affiliate' : 'is-not-affiliate',
      this.application_name ? this.application_name : null,
    )
    this._setUpGroups()
    eventBus.$emit('stepChangeCompleted')
    eventBus.$on('submitStep', () => {
      this.$store.dispatch('UPDATE_INPUT_VALUES', this.inputs)
      document.getElementById('step_submit').click()
    })

    this.offerSelection = []
    this.inputs.insurer = null

    // set default values
    let values = {}

    if (shared) {
      try {
        if (productStep) {
          this.$store.commit('SET_ACTIVE_STEP', productStep)
        }
      } catch (e) {
        console.log('ERROR AT JWT PART', e)
      }
    } else if (this.isOffer && this.$store.state.contract.contract) {
      const { selectedModules } = this.$store.state.contract.contract
      values.selectedModules = selectedModules
    } else if (!this.$store.state.checkout.application.modulesChanged && !this.isOffer) {
      // preselect properties with state 'optional-preselected'
      this.selectModules('default')
    }

    this.mode !== 'offer' ? this.selectModules('all') : ''

    this.$store.dispatch('UPDATE_INPUT_VALUES', values)

    if (this.insuredSums.indexOf(this.inputs.insuredSum) === -1) {
      this.$store.dispatch('UPDATE_INPUT_VALUES', {
        insuredSum: this.insuredSums[1],
      })
    }

    if (this.possibleDeductibles.indexOf(this.inputs.deductible) === -1) {
      this.$store.dispatch('UPDATE_INPUT_VALUES', {
        deductible: this.possibleDeductibles2[0],
      })
    }

    if (window) {
      const touchDeviceViewContent = document.querySelector('.offer-container') || document.querySelector('.view-content--touch-device')
      if (touchDeviceViewContent) {
        touchDeviceViewContent.addEventListener('scroll', this.scrollHandler, { passive: true })
      } else {
        window.addEventListener('scroll', this.scrollHandler, { passive: true })
      }
    }

    eventBus.$emit('insurerCount', this.insurers.length)

    eventBus.$on('openOfferModal', () => {
      this.showRegModal = true
    })

    if (!this.insurers.length && this.possibleDeductibles2.indexOf(this.inputs.deductible) < this.possibleDeductibles2.length - 1) {
      this.$store.dispatch('UPDATE_INPUT_VALUES', {
        deductible: this.possibleDeductibles2[this.possibleDeductibles2.indexOf(this.inputs.deductible) + 1],
      })
    }
    this.$store.commit('SET_CHECKOUT_LOADING_OVERLAY', false)

    if (this.isOffer && this.offerSettings.showRating === true) {
      this.$store.commit('SET_SCORE_VISIBILITY', true)
    } else if (this.isOffer) {
      this.$store.commit('SET_SCORE_VISIBILITY', false)
    }
    // hide scores for affiliate link
    // for some reason isAffiliate returns true in Offer view.
    if ((this.isAffiliate || this.isWWW) && !this.isOffer) {
      this.$store.commit('SET_SCORE_VISIBILITY', false)
      this.$store.commit('SET_SORTING_LOGIC', 'abc')
    } else if (this.isOffer) {
      // if there is a setting coming from offer defining what sorting should be used
      if (this.$store.getters.offer.sortBy) {
        // this.sortBy = this.$store.getters.offer.sortBy // rigger the computed prop to recalculate (insurers)
        this.$store.commit('SET_SORTING_LOGIC', this.$store.getters.offer.sortBy)
      } else {
        // default
        this.$store.commit('SET_SORTING_LOGIC', 'score')
      }
    } else {
      this.$store.commit('SET_SORTING_LOGIC', 'score')
    }
    this.sortBy = this.insurerSortingKey

    // lib & code to sync header and table content
    if (this.mode === 'offer') {
      // for now only for offer mode, because it can ruin layout in other modes
      // later can be set to other modes with changing styles and testing
      const syncscroll = require('../assets/js/syncscroll/syncscroll.js')
      syncscroll.init()

      function showAccordionSyncScroll(el) {
        const changedElement = el
        const syncedElements = syncscroll.names.trTable
        let primaryElement

        for (let i = 0; i < syncedElements.length; i++) {
          if (syncedElements[i] !== changedElement) {
            primaryElement = syncedElements[i]
            break
          }
        }
        changedElement.scrollLeft = primaryElement.scrollLeft
      }
      eventBus.$on('showAccordionSyncScroll', showAccordionSyncScroll)
    }
    // lib & code to sync header and table content
  },
  destroyed: function () {
    eventBus.$off('submitStep')
    document.querySelector('html').classList.remove('product-table')
    document.body.classList.remove(
      'printable-css',
      'body-offer',
      `${this.mode}-mode`,
      this.isAffiliate ? 'is-affiliate' : 'is-not-affiliate',
      this.application_name ? this.application_name : null,
    )

    const touchDeviceViewContent = document.querySelector('.offer-container') || document.querySelector('.view-content--touch-device')
    touchDeviceViewContent ? touchDeviceViewContent.removeEventListener('scroll', this.scrollHandler, { passive: true }) : ''
    window ? window.removeEventListener('scroll', this.scrollHandler, { passive: true }) : ''
  },
  beforeRouteEnter(to, from, next) {
    const { shared } = to.query
    const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store
    eventBus.$emit('isLoading', true)

    if (shared) { // deprecated ? we dont use sharing anymore
      const decoded = jwtDecodeFunc(shared)
      storeInstance.dispatch('UPDATE_INPUT_VALUES', decoded)
      storeInstance.commit('SET_MODULES_CHANGED')
    }

    storeInstance.commit('SET_CHECKOUT_LOADING_OVERLAY', true)

    storeInstance.commit('SELECT_ALL_MODULES')
    next()
  },
  beforeRouteUpdate(to, from, next) {
    const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store
    eventBus.$emit('isLoading', true)
    storeInstance.dispatch('CALCULATE_CYBER_PRICES').then(() => {
      eventBus.$emit('isLoading', false)
      next()
    })
  },
  methods: {
    setModalBody(content, isVisibleHeader = false) {
      if (content) {
        this.modalBody = content;
        this.isVisibleModalHeader = isVisibleHeader;
      }

      return this;
    },
    isPricePerformWinner() {
      return (
        this.$store.state.offer?.offer?.showPricePerformWinner &&
        this.pricePerformWinner &&
        this.$store.state.offer?.offer?.selection.includes(this.pricePerformWinner?.key)
      )
    },
    isInsurerPricePerformWinner(insurerKey) {
      return (
        this.$store.state.offer?.offer?.showPricePerformWinner && this.pricePerformWinner && this.pricePerformWinner?.key === insurerKey
      )
    },
    sortChange(sortBy) {
      this.$store.commit('SET_SORTING_LOGIC', sortBy)
      this.sortBy = sortBy
    },
    // TODO this is updating in a really suspicious way,
    // I think it multiplies the calls. Inspection needed.
    updateOfferSelection(selection) {
      this.offerSelection = selection
    },
    updatePhoneNumber(res) {
      this.offer.phone = res.number
    },
    getOptionalDropdownValue(items, selected) {
      const item = items.find((item) => item.value === selected)
      return item ? item.label : ''
    },
    removeSelectedInsurer(selectedInsurerKey) {
      this.offerSelection = this.offerSelection.filter((i) => i !== selectedInsurerKey)

      if (this.inputs.insurer === selectedInsurerKey) {
        this.inputs.insurer = 'no-recommendation'
      }
    },
    addAdditionalInsurer() {
      this.offerSelection.push(this.additionalInsurer)
      this.additionalInsurer = null
    },
    getInsurerName(insurerKey) {
      const insurer = this.insurers.find((i) => i.key === insurerKey)
      return insurer ? insurer.label : ''
    },
    forceUpdateInsurers() {
      try {
        // fixes problem with dynamic rendering in child components
        this.$refs.tableStatisticsComponent.forceUpdateInsurers()
        this.$refs.propertyGridComponent.forceUpdateInsurers()
        this.$refs.insurersComponent.forceUpdateInsurers()
        this.$refs.insurersPrintComponent.forceUpdateInsurers()
      } catch (err) {
        console.error('forceUpdateInsurers() error:', err)
      }
    },
    scrollHandler() {
      const container =
        document.querySelector('.offer-container') || document.querySelector('.view-content--touch-device') || document.documentElement
      const stickyCol = document.querySelector('.filter-options.sticky-col')
      const rect = stickyCol?.getBoundingClientRect()
      const stickyColHasZeroOffset = rect.left === 0
      const touchDeviceClass = document.querySelector('.view-content--touch-device')
      const left = (window.pageXOffset || container.scrollLeft) - (container.clientLeft || 0)
      if (touchDeviceClass && left > 50) {
        this.showShadow = true
        this.shadowColWidth = rect.right + 'px'
      } else if (stickyColHasZeroOffset) {
        this.showShadow = true
      } else {
        this.showShadow = false
      }

      // sticky-header
      const screen = window.screen.width < 768 ? 'sm' : 'md+'

      const stickyBreakpoints = {
        'md+': {
          on: this.mode === 'offer' ? 1000 : 1000,
          off: this.mode === 'offer' && this.offerSettings?.insurerSelected ? 720 : this.mode === 'offer' ? 700 : 800,
        },
        sm: {
          on: this.mode === 'offer' ? 1700 : 1000,
          off: this.mode === 'offer' ? 1800 : 800,
        },
      }

      const isWideMobile = this.$isMobile && window.screen.width > 576
      if ((!this.$isMobile || isWideMobile) && window.pageYOffset >= stickyBreakpoints[screen].on) {
        this.isTblHeaderSticky = true
      } else if ((!this.$isMobile || isWideMobile) && window.pageYOffset <= stickyBreakpoints[screen].off) {
        this.isTblHeaderSticky = false
      }
      // /sticky-header
    },
    goBack() {
      this.$router.push({ name: 'produktauswahl' })
    },
    checkRiskQuestion(propertyKey) {
      let riskQuestionKey = this.riskQuestionMapping[propertyKey]
      return riskQuestionKey && this.riskAnswers[riskQuestionKey]
    },
    optionalChanged(insurer, propertyKey) {
      let groupModuleName = ''
      const multiModuleMap = this.insurersWithMultiModules
      // check if this is a submodule?
      if (multiModuleMap[insurer]) {
        groupModuleName = this.findMatchingModel(this.insurerPrices[insurer], multiModuleMap[insurer], propertyKey)

        if (groupModuleName) {
          if (this.inputs.selectedModules[insurer].indexOf(propertyKey) === -1) {
            // turn all group modules off
            this.insurerPrices[insurer].modules[groupModuleName].modules.forEach((subModule) => {
              this.$store.commit('DESELECT_MODULE', {
                insurer: insurer,
                module: subModule,
              })
            })
          } else {
            // turn all group modules on
            this.insurerPrices[insurer].modules[groupModuleName].modules.forEach((subModule) => {
              if (this.inputs.selectedModules[insurer].indexOf(subModule) === -1) {
                this.$store.commit('SELECT_MODULE', {
                  insurer: insurer,
                  module: subModule,
                })
              }
            })
          }
        }
      }

      this.$store.commit('SET_MODULES_CHANGED')
    },
    isAvailable(insurer, key) {
      if (insurer && insurer.properties && insurer.properties[key]) {
        return insurer.properties[key].state === 'available' && !insurer.properties[key].disabled
      } else {
        return false
      }
    },
    isOptional(insurer, key) {
      if (insurer && insurer.properties && insurer.properties[key]) {
        return (
          (insurer.properties[key].state === 'optional' || insurer.properties[key].state === 'optional-preselected') &&
          !insurer.properties[key].disabled
        )
      } else {
        return false
      }
    },
    isSelected(insurer, key) {
      if (this.inputs.selectedModules[insurer.key] === undefined || this.inputs.selectedModules[insurer.key] === null) {
        return false
      }
      return this.inputs.selectedModules[insurer.key].includes(key)
    },
    submitForm: function () {
      if (this.offerSelection && this.offerSelection.length) {
        this.$store.commit('UPDATE_OFFER_SELECTION', this.offerSelection)
      }

      if (!this.inputs.insurer && this.offerSelection && this.offerSelection.length) {
        this.inputs.insurer = this.offerSelection[0]
      } else if (this.insurers.length === 1) {
        this.inputs.insurer = this.insurers[0].key
      } else if (!this.inputs.insurer) {
        return eventBus.$emit('alertUser', {
          type: 'error',
          title: 'Bitte wählen Sie ein Produkt aus.',
          message: '',
          customCloseBtnText: 'OK',
          customCloseBtnClass: 'btn btn-primary ',
        })
      }

      if (this.$analytics) {
        this.$analytics.track(this.$route.path, {
          label: 'insurer',
          value: this.inputs.insurer,
        })
      }

      this.stepData.completed = true
      return eventBus.$emit('changeStepForward', this.stepData)
    },
    selectInsurer(insurer) {
      this.$store.dispatch('UPDATE_INPUT_VALUES', {
        insurer: insurer.key,
        answers: {},
      })
      this.$store.commit('RESET_OFFER')
      this.$store.commit('UPDATE_OFFER_SELECTION', [insurer.key])

      if (this.isAffiliate) {
        this.showRegModal = true
      } else {
        this.step.completed = true
        return eventBus.$emit('changeStepForward', this.stepData)
      }
    },
    createOffer(skipModal) {
      if (this.offerSelection && this.offerSelection.length) {
        this.$store.commit('UPDATE_OFFER_SELECTION', this.offerSelection)
      }

      if (!this.inputs.insurer) {
        if (this.offerSelection.length === 1) {
          // preselect the insurer
          if (!this.offerSelection[0].includes('corvus')) {
            this.inputs.insurer = this.offerSelection[0]
          } else {
            // console.log('corvus insurer selected, not possible to preselect')
            this.inputs.insurer = 'no-recommendation'
          }
        } else {
          console.error('no insurer was selected for the offer, not possible to preselect')
          this.inputs.insurer = 'no-recommendation'
        }
      }

      if (skipModal || !(this.isBroker && !this.isAffiliate) || (this.isBroker && this.offerSelection.length > 1)) {
        if (this.stepData) {
          this.stepData.completed = true
        }

        if (this.$analytics) {
          this.$analytics.track(this.$route.path, {
            label: 'offerSelection',
            value: this.offerSelection,
          })
        }

        this.showRegModal = true
      } else {
        this.showOfferInsurerModal = true
      }
    },
    getTopOffset(element) {
      let offsetTop = 0

      while (element) {
        offsetTop += element.offsetTop
        element = element.offsetParent
      }

      return offsetTop
    },
    radioClicked(insurer) {
      if (this.inputs.insurer === insurer.key) {
        this.$store.dispatch('UPDATE_INPUT_VALUES', { insurer: null })
      }
    },
    getPriceToolTip(insurer) {
      let tooltipTexts = []

      if (insurer.tooltips) {
        insurer.tooltips.forEach((tooltip) => {
          if (ConditionCheck.check(this.inputs, tooltip.condition)) {
            tooltipTexts.push(tooltip.text)
          }
        }, this)
      }

      return tooltipTexts.length ? tooltipTexts.join(', ') : undefined
    },
    selectModules(type = 'default') {
      switch (type) {
        case 'default':
          this.$store.commit('DEFAULT_SELECT_MODULES')
          break
        case 'none':
          this.$store.commit('DESELECT_ALL_MODULE')
          break
        case 'all':
          this.$store.commit('SELECT_ALL_MODULES')
          break
      }
    },
    saveOffer() {
      if (this.offer.sortBy === '') {
        this.offer.sortBy = null
      }

      if (
        ((this.$store.state.user && this.$store.state.user.email === 'info@auxmed.de' && this.isBroker) ||
          (this.affiliateBroker && this.affiliateBroker.agency === 'auxmed')) &&
        this.inputs.industry.w === '86230' &&
        this.inputs.insurer === 'victor-gesundheitswesen-heilwesen'
      ) {
        this.offer.campaign = 'victor-fvdz'
      }
      const action = this.isAffiliate ? 'INSERT_AFFILIATE_OFFER' : 'INSERT_OFFER'

      if (this.inputs.insurer) {
        const selectedInsurer = this.insurers.find((i) => i.key === this.inputs.insurer)

        if (selectedInsurer) {
          this.offer.net = selectedInsurer.prices.net
          this.offer.gross = selectedInsurer.prices.gross
        }
      }
      if (this.isAffiliate) {
        this.offer.selection = this.$store.state.checkout.application.offer.selectedInsurer
      } else {
        this.offer.selection = this.$store.state.offer.selection
      }

      this.submitted = true

      if (this.isAffiliate && (!this.offer.dataPrivacyCheck || !this.offer.contactBrokerCheck)) {
        return
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isLoadingOverlay = true
          this.$store.commit('RESET_OFFER')
          this.$store
            .dispatch(action, this.offer)
            .then((response) => {
              if (this.isAffiliate) {
                this.showAffiliateSuccess = true
                this.isLoadingOverlay = false
              } else {
                if (this.$analytics) {
                  this.$analytics.track(this.$route.path, {
                    label: 'offerCreated',
                    value: response.data.id,
                  })
                }

                this.$router
                  .push({
                    name: 'AngeboteDetail',
                    params: { offerId: response.data.id },
                  })
                  .then((res) => {
                    this.showRegModal = false
                    this.isLoadingOverlay = false
                  })
              }
            })
            .catch(() => {
              return eventBus.$emit('alertUser', {
                type: 'error',
                title: 'Es ist ein Fehler aufgetreten. Das Angebot konnte nicht gespeichert werden.',
                message: '',
                customCloseBtnText: 'OK',
                customCloseBtnClass: 'btn btn-primary ',
              })
            })
        }
      })
    },
    getSalutation() {
      const lastname = this.offer.lastname || 'Mustermann'
      const salutation = this.offer.salutation || 'Herr'

      if (this.offer.salutationOverwrite) {
        return this.offer.salutationOverwrite
      } else {
        if (!this.offer.lastname) {
          return `Sehr geehrte Damen und Herren,`
        } else if (salutation === 'Herr') {
          return `Sehr geehrter Herr ${lastname},`
        } else {
          return `Sehr geehrte Frau ${lastname},`
        }
      }
    },
    getFieldClass(fieldName) {
      if (!this.fields[fieldName]) {
        return
      }

      return {
        'is-danger': this.errors.has(fieldName),
        touched: this.fields[fieldName].dirty,
      }
    },
    getNote(property) {
      let sublimit
      let sublimit2
      let note = property.note

      if (property.sublimits) {
        // here we deal with an array of sublimits (this is some historical data i guess ?)
        if (Array.isArray(property.sublimits)) {
          const subLimitItem = property.sublimits.find((sublimit) => ConditionCheck.check(this.inputs, [sublimit.condition]))

          if (subLimitItem) {
            sublimit = subLimitItem.sublimit
          }
          // here with a single sublimit
        } else {
          // either there is defined a sublimit for insuredSum or netRevenue,
          // it is not perfect, should be refactored later, is ugly and does not allow AND conditions
          sublimit = property.sublimits[`is${this.inputs.insuredSum}`] || property.sublimits[`rev${this.inputs.netTurnOver}`]
        }

        if (sublimit) {
          note = note.replace('{sublimit}', sublimit)
        }
      }

      if (property.sublimits2) {
        if (Array.isArray(property.sublimits2)) {
          const subLimitItem = property.sublimits2.find((sublimit) => ConditionCheck.check(this.inputs, [sublimit.condition]))

          if (subLimitItem) {
            sublimit2 = subLimitItem.sublimit
          }
        } else {
          sublimit2 = property.sublimits2[`is${this.inputs.insuredSum}`]
        }

        if (sublimit2) {
          note = note.replace('{sublimit2}', sublimit2)
        }
      }

      return note
    },
    _encodeSharedLink(data) {
      return jwt.sign(data, ENCODE_SECRET)
    },
    _setUpGroups() {
      const groups = [
        {
          key: 'damage',
          label: 'Was ist versichert?',
        },
        {
          key: 'ownDamage',
          label: 'Leistung bei Eigenschäden',
        },
        {
          key: 'liability',
          label: 'Leistung bei Drittschäden',
        },
        {
          key: 'other',
          label: 'Sonstiges',
        },
        {
          key: 'services',
          label: 'Services',
        },
      ]

      try {
        groups.map((group) => {
          let properties = this.insuranceProperties.filter((property) => property.group === group.key)
          let count = 0

          properties.map((property) => {
            property.index = count
            count++
            return property
          })

          group.properties = properties

          return group
        })
      } catch (err) {
        console.error('_setUpGroups insuranceProperties.filter error:', err)
      }
      this.groups = groups
    },
  },
}
</script>

<style lang="scss">
.insurer--recommendation-declined {
  filter: grayscale(100%);
  opacity: 0.5;
}
</style>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/tooltip';
@import '../scss/form';
@import '../scss/button';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../scss/print/produktvergleich.scss';

.container {
  .the-table,
  .the-table .form-wrapper {
    width: 100%;
  }
}

.insurers-qnty-1 ::v-deep {
  .filter-companies,
  .prices,
  .price,
  .precentage-row,
  .precentage-col,
  .tb-row,
  .tb-cell {
    width: 100%;
  }

  .filter-companies .cyb-card {
    display: flex;
    justify-content: center;
  }
}

.insurers-qnty-2 ::v-deep {
  .filter-companies,
  .insurers,
  .prices,
  .precentage-row,
  .tb-row {
    width: 100%;
  }

  .company.insurer,
  .price,
  .precentage-col,
  .tb-row > .tb-cell,
  .tb-cell.insurer {
    width: 50%;
  }
}

.custom-tooltip {
  width: 100%;
  max-width: 420px;

  ul {
    margin-bottom: 0;
  }

  ::v-deep .tooltip-inner {
    padding: 1rem;
  }

  @include media-breakpoint-down(xs) {
    max-width: 300px;
    left: 30px !important;

    ::v-deep .tooltip-inner {
      overflow: scroll;
      max-height: 450px;
    }
  }
}

.phone-resolver-container {
  border: none;
  padding-top: 0;
  padding-left: 0;
}

h1 {
  color: $darkBlueTone;
  font-size: 40px;
  font-weight: 800;
}

h2 {
  color: $placeHolderColor;
  font-size: 1.1rem;
}

.footer-btns {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  .btn {
    white-space: nowrap;
  }

  // this is here to fix the text breaking out of the box on popup
  .btn.back-zu {
    white-space: normal;
  }

  .btn-style {
    font-weight: normal;
    border-width: 0px;
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.market-comparison {
  &--offer {
    &.table-header-sticky {
      position: relative;
      z-index: 1005;
    }

    .optional-radio,
    .optional-dropdown {
      pointer-events: none;
    }

    ::v-deep table {
      width: 100%;
      table-layout: fixed;
    }

    ::v-deep .precentage-row {
      width: 100%;
    }

    .filter {
      &--mobile {
        display: none;
      }
    }

    ::v-deep .filter-row {
      margin-bottom: 1rem;
    }

    ::v-deep .scores-info {
      background: #417e9b;
      color: #fff;

      p {
        color: #fff;
      }

      a {
        color: #fff;
        text-decoration: underline;

        &:hover {
          font-weight: 600;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      .filter {
        &--mobile {
          display: block;
          position: sticky;
          left: 0.5rem;
        }

        &:not(.filter--mobile) {
          display: none;
        }
      }

      ::v-deep .sticky-col.details {
        min-width: auto;
        width: auto;
      }
    }
  }
}

.optional-radio {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-group {
  &:last-of-type {
    margin-bottom: 0;
  }
}

::v-deep .optional {
  display: none;

  + label {
    position: relative;
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    display: inline-block;
    height: 24px;
    width: 24px;
    border-radius: 20rem;
    border: 2px solid #000;
    // background-image: url('https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/radio_btn_optional_normal.svg');

    &:after {
      position: absolute;
      top: 1px;
      left: 28px;
      content: ' ';
      font-size: 0.8rem;
      color: $lightGrey2;
    }
  }

  &:checked + label {
    border: none;
    background-image: url('https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/check_optional_selected.svg');
  }

  &:disabled + label {
    cursor: default;
  }
}

.circle-icon {
  width: 24px;

  &__tooltip {
    position: absolute;
    z-index: $zIndexTooltip;
    top: calc(50% - 15px);
    left: calc(50% + 10px);
  }

  &--with-tooltip {
    cursor: pointer;
  }
}

.start-icon {
  width: 18px;
}

.row__data,
.row__price,
.row__head {
  margin-left: 0;
  margin-right: 0px;
  padding-top: 0 !important;
  background: $primary;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  border-radius: 6px;

  .row-head,
  .row-cells {
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 28px;
  }

  .row-head {
    padding-right: 0;
    text-align: center;
    max-width: 350px;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    .circle-icon {
      position: absolute;
      right: 4px;
      top: 4px;
    }
  }

  .row-cells {
    padding-left: 10px;
    padding-right: 0;

    .insurer {
      position: relative;
      line-height: 24px;
      text-align: center;

      @include media-breakpoint-up(md) {
        border-left: 1px solid #fff;
      }

      + .insurer {
        border-left: 1px solid #fff;
      }
    }
  }
}

.row__head {
  margin: 16px 0 4px 0;
}

.row__head.first {
  margin: 0 0 4px 0;
}

.row__data {
  background: none;
  position: relative;
  border-radius: 0;
  color: #000;
  font-weight: normal;

  &--highlighted {
    color: $blueTone;
    background: mix($blueTone, #fff, 20%) !important;
    border-bottom: 1px solid mix($blueTone, #f0f0f0, 30%);

    .row-head {
      background: mix($blueTone, #fff, 20%) !important;
    }

    .optional-radio label:after {
      color: $blueTone;
    }
  }

  &--odd {
    @include media-breakpoint-up(md) {
      background: none;
    }

    .row-head {
      background: #fff;
      z-index: 1;
    }
  }

  &--even {
    @include media-breakpoint-up(md) {
      background: #f0f0f0;
    }

    .row-head {
      z-index: 1;
      background: #f0f0f0;
    }
  }

  &--border-top {
    border-top: 2px solid $placeHolderColor;
    border-right: 2px solid $placeHolderColor;
    border-left: 2px solid $placeHolderColor;
  }

  &--border-bottom {
    border-right: 2px solid $placeHolderColor;
    border-bottom: 2px solid $placeHolderColor;
    border-left: 2px solid $placeHolderColor;
  }
}

.row__price {
  margin-bottom: 9px;
  color: #fff;
  background: $darkBlueTone;

  .row-head {
    padding: 12px;
    line-height: 1;
    background: $darkBlueTone;
    z-index: 1;

    div {
      font-size: 1rem;

      @include media-breakpoint-up(sm) {
        font-size: 1.2rem;
      }
    }
  }

  .row-cells {
    font-size: 1.2rem;
    line-height: 1;
  }

  small {
    color: rgba(255, 255, 255, 0.8);
  }
}

.support-note {
  position: absolute;
  left: calc(100% + 10px);
  top: 100%;
  z-index: $zIndexComparisonSupportNote;
  margin-top: -24px;
  min-width: 150px;
  text-align: center;
  font-weight: bold;
}

.sticky-header {
  padding-top: 22px;
  background: #fff;

  &--offset {
    @include media-breakpoint-down(sm) {
      top: 50px !important;
    }
  }
}

.note-exklusiv {
  position: absolute;
  right: 10px;
  top: 10px;
}

::v-deep .note {
  padding: 4px;
  color: $darkBlueTone;
  font-size: 0.85rem;
  line-height: 1.3;
  width: 100%;
  // word-wrap: anywhere;
  word-break: break-word;
}

#step_form {
  @include media-breakpoint-down(sm) {
    width: fit-content;
  }
}

.resume-info {
  margin-bottom: 1.5rem;
}

.filter {
  //padding-bottom: 24px;

  &:not(&--offer) {
    @include media-breakpoint-down(sm) {
      position: sticky;
      left: 0;
      width: calc(100vw - 2rem);
    }
  }

  &--offer {
    padding: 1rem 1.5rem;
    background: #ebf0f2;
    height: 100%;
    border-radius: 0.25rem;

    ::v-deep .details.sticky-col {
      position: relative;
      width: 100%;
      min-width: 100%;
    }

    ::v-deep .statistics-table {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      min-width: 290px;
      width: 290px;
    }

    @include media-breakpoint-down(xs) {
      width: calc(100vw - 3rem);
    }

    ::v-deep .title {
      font-size: 1.75rem;
      font-weight: bold;
      margin-bottom: 2px;

      @include media-breakpoint-down(xs) {
        font-size: 1.6rem;
      }
    }

    ::v-deep .subtitle {
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
    }

    ::v-deep .product-details__text {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .product-details__industry {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0.5rem;
      }
    }

    .details-group {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;
    }

    ::v-deep .detail-field {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        gap: 0;
        margin-bottom: 0.5rem;
      }

      @include media-breakpoint-down(xs) {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .property {
    border: 1px solid $lightGrey3;

    .row-head {
      background: $lightGrey3;
      padding-left: 30px;
      padding-right: 30px;
      font-weight: bold;
    }

    .insurer + .insurer {
      border-left: 1px solid $lightGrey3 !important;
    }
  }
}

.group-head {
  padding-left: 8px;
  height: 43px;
  line-height: 43px;
}

.controls {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -85px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: $lightGrey;
  border-bottom: 1px solid $lightGrey3;

  &__item {
    flex: 1;
    padding: 8px;
    border: 1px solid $lightGrey3;
  }

  &__item--wide {
    min-width: 142px;
  }

  &__select {
    border: 0;
    background: none;
    text-align-last: center;
    padding: 0;
    height: 20px !important;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  &__label {
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
  }
}

.compressed {
  .optional {
    + label {
      &:after {
        content: ' ';
      }
    }
  }
}

.cl1 {
  width: 402px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 1rem;
  position: sticky;
  background: #fff;
  left: 0;
  z-index: 1;
}

.cl2 {
  display: flex;
  gap: 1rem;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;

  .insurer {
    width: 290px;
    text-align: center;
  }
}

.row-head {
  position: relative;
  transition: box-shadow 0.3s ease-in-out;

  &--has-shadow {
    box-shadow: 13px 0 27px -2px rgba(50, 50, 93, 0.15);
  }

  &.cl1 {
    padding-right: 36px;
    position: sticky !important;
  }
}

.col-has-shadow {
  transition: 0.1s;

  @include media-breakpoint-up(md) {
    position: relative;

    &::before {
      content: '';
      position: fixed;
      top: 100px;
      left: 0;
      width: var(--shadow-col-width, 402px);
      height: 100%;
      box-shadow: 13px 0 27px -2px rgba(50, 50, 93, 0.15);
      z-index: 900;
      pointer-events: none;
    }
  }

  &.view-content--touch-device {
    margin-left: -1rem;
  }
}

.horizontal-sticky {
  position: sticky;
  left: 12px;
  z-index: 1;
}

.desktop-controls {
  background: #fff;
}

.optional-dropdown {
  max-width: 150px;
}

.loading-overlay {
  position: fixed;
  z-index: $zIndexLoading;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  user-select: none;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.25s;

  &--in {
    opacity: 1;
  }
}

::v-deep .sticky-col {
  position: sticky;
  left: 0;
  width: 402px;
  min-width: 402px;
  z-index: 5;

  @include media-breakpoint-down(sm) {
    position: static;
    min-width: auto;
    width: auto;
    // width: 100%;
  }

  &.details {
    position: sticky;
    min-width: 374px;
    width: 374px;
  }
}

::v-deep .sticky-row {
  position: sticky;
  left: 0;
  z-index: 5;
}

.details {
  max-width: 500px;
  left: 1rem;

  &__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 1.25rem;
    margin-bottom: 1rem;

    span {
      margin-bottom: 0.125rem;
    }

    .link {
      // font-size: 1rem;
    }
  }

  .link {
    // font-size: 1rem;
  }
}

.product-details {
  margin-bottom: 2.5rem;
}

.filter-row {
  display: flex;
  gap: 0.5rem;
  width: 100% !important;
  margin-bottom: 0.5rem;

  @include media-breakpoint-down(sm) {
    // margin: 0.25rem;
  }

  @include media-breakpoint-down(sm) {
    gap: 0.5rem;
  }

  &--not-offer {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      width: fit-content;

      .filter-options {
        max-width: 510px;
      }

      .sticky-col {
        position: sticky;
        width: calc(100vw - 2rem);
      }
    }
  }
}

// sticky
div.filter-row-wrapper.filter-row-wrapper--sticky ::v-deep {
  position: sticky;
  top: 123px;
  background: #fff;
  // margin-top for non-offer view, fixes "sticky on scroll" bug
  margin-top: 680px;
  z-index: 99;

  .filter-row {
    margin-bottom: 0.25rem !important;
  }

  .cyb-card,
  .company.insurer {
    padding: 0.5rem 1rem;
  }

  .filter-option {
    display: none;
  }

  .apply-btn {
    margin-top: 0 !important;
  }

  .company.insurer {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .offer-badge {
    display: none;
  }

  .insurer {
    &__image {
      min-height: auto;
      margin-bottom: 0;
      margin-right: 1.5rem;
      width: 65%;

      img {
        max-height: 1.5rem;
      }
    }

    &__image + .insurer__bottom {
      margin-left: -1.5rem;
    }

    &__bottom {
      justify-content: center;
      margin-top: 0;

      .action {
        display: flex;
        margin-top: 0;

        span {
          display: none !important;
        }
      }

      &--empty {
        width: 1.5rem;
      }
    }

    .custom-checkbox {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }

  .cyb-table {
    .sticky-col {
      .cyb-card {
        display: none;
      }
    }

    .tb-cell {
      display: none;
    }

    .th-cell {
      padding: 0.5rem 1.5rem;
      margin-bottom: 0;
    }
  }

  .filter--offer {
    padding: 0.5rem 1rem;

    .details > div *:not(.title),
    .product-details__text {
      display: none;
    }

    .details .title {
      font-size: 1rem;
    }

    .filter.filter--offer {
      padding: 0.5rem 1rem;
    }
  }

  .company.company--offer.insurer {
    .insurer__image {
      margin-right: 0;

      img {
        max-height: 50px;

        @include media-breakpoint-down(sm) {
          max-height: 24px;
        }
      }
    }

    .insurer__bottom {
      &.insurer__bottom--empty {
        display: none;
      }
    }
  }
}

.cyb-layout-checkout div.filter-row-wrapper.filter-row-wrapper--sticky ::v-deep {
  top: 115px;

  .company.insurer {
    justify-content: center;
  }
}

div.market-comparison--offer .filter-row-wrapper--sticky ::v-deep {
  top: 0;
}

.filter-options {
  .cyb-card {
    width: 402px;
    height: 100%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.cyb-card {
  .view-type-switch {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .label {
      font-weight: 900;
      font-size: 0.825rem;
    }

    .custom-position{
      position: relative;
      top: -3px;
    }
  }
}

::v-deep .filter-option {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(.filter-option--checkbox) {
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  label[for='sort-by'],
  label[for='optional-blocks'] {
    position: relative;
  }

  &__info-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0.5rem;
    padding: 0;
    min-width: fit-content;
  }

  label,
  .link {
    font-size: 0.825rem;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5rem;
    }
  }

  select,
  .control {
    max-width: 192px;
    width: 100%;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &--checkbox {
    @include media-breakpoint-down(sm) {
      label {
        margin-right: 1rem;
        margin-bottom: 0;
        white-space: nowrap;
      }
    }
  }

  ::v-deep &--checkbox-offer {
    justify-content: flex-start;
    gap: 1rem;

    @include media-breakpoint-down(sm) {
      label {
        width: auto;
        margin-bottom: 0;
        order: 2;
      }

      gap: 0.25rem;
    }

    ::v-deep label {
      font-weight: normal;
      font-size: 1rem;
      margin-bottom: 0;
    }

    ::v-deep .cd-control {
      width: auto;

      @include media-breakpoint-down(sm) {
        label {
          order: 1;
        }
      }
    }
  }

  &.filter-option--checkbox-offer {
    justify-content: flex-start;

    label {
      font-weight: normal;
      font-size: 1rem;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
}

.apply-btn {
  @include media-breakpoint-up(lg) {
    width: 100%;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.filter-companies {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cyb-card {
    flex-grow: 1;
    width: auto;
    height: 100%;
    display: flex;
  }
}

.nav-btns {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 3;
}

.statistics-table {
  padding-bottom: 0.5rem;

  @include media-breakpoint-down(xs) {
    margin-bottom: 0.5rem;
  }
}

.view-content {
  position: relative;

  &--touch-device {
    overflow: auto;
  }
}

.table-wrapper {
  width: 100%;
  overflow: auto;
}

#share_market_comparison {
  display: flex;

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}

.form-wrapper {
  width: fit-content;
}


.insured-sum-row {
  display: flex;
  gap: 0.5rem;
}

.insured-sum-filler {
  width: 402px;
  min-width: 402px;

  @include media-breakpoint-down(sm) {
    width: 290px;
    min-width: 290px;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.insured-sum-options {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
  color: $blackColor;
  font-size: 0.875rem;
  font-weight: 900;

  &__insurer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background: #e0f2fa;
    padding: 1rem;
    border-radius: 0 0 0.25rem 0.25rem;

    @include media-breakpoint-up(sm) {
      padding: 0.85rem 1rem 0 1rem;
      gap: 0.25rem;
    }
  }

  &__option {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.125rem 1rem;

    @include media-breakpoint-down(sm) {
      height: 70px;
    }

    @include media-breakpoint-down(xs) {
      height: fit-content;
    }
  }

  &__sum {
    font-weight: 400;
    text-align: center;
    font-size: 0.8rem;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.insured-sum-options-v2 {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: -0.125rem;
  margin-bottom: 1rem;

  .insured-sum-option {
    background: rgba(#31a9e0, 0.25);
    height: 40px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    padding: 0.75rem 1.275rem;

    @include media-breakpoint-down(sm) {
      height: 70px;
    }

    .text1 {
      font-weight: 900;
      text-wrap: nowrap;
    }
  }
}

::v-deep .recommendation-frame {
  display: none;
}

.print-insurers-margin {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.print-insurers-wrap {
  table-layout: fixed;
}

.print-insurers-headline {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.5rem;
  background-color: #31a9e0;
  color: #fff;
  font-size: 1.5rem;
  padding: 0.3rem 1.25rem;
  border-radius: 0.25rem;
  margin-bottom: 0.75rem;
}

.extra-z-index {
  z-index: 10;
}

@media print {
  .page-break-after {
    page-break-after: always;
  }

  .new-page {
    page-break-before: always;
  }
}

</style>
