<template>
  <div :class="{'is-offer': isOffer, 'no-score': !isScoreVisible}">
    <!-- v-for="(group, groupIndex) in insuranceProperties.groups" -->
    <table
      class="group-table"
      v-for="(group, groupIndex) in insuranceProperties.groups"
      :key="`acc-group-${groupIndex}`"
    >
      <thead>
        <tr class="table-heading-row">
          <th>
            <div class="table-heading">
              {{ group.label }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="prop-body">
        <table
          v-for="(subgroup, subgroupIndex) in group.subgroups"
          :key="`acc-group-${groupIndex}__acc-subgroup-${subgroupIndex}`"
          class="subgroup-table"
        >
          <tbody>
            <tr>
              <td class="subgroup-label-col">
                <div class="subgroup-label">
                  <span class="">{{ subgroup.label }}</span>
                </div>
              </td>
              <template v-if="isScoreVisible">
                <td
                  class="precentage-col"
                  :class="[
                    `precentage-col--${insurers.length}`,
                    {'precentage-col--disabled': insurer.disabled},
                    (isQuoteExpiredMixin(insurer.key) || isRecommendationDeclinedMixin(insurer.key)) && isOffer ? 'insurer--recommendation-declined' : ''
                  ]"
                  v-for="(insurer, insIndex) in insurers"
                  :key="insurer.key"
                >
                  <Precentage
                    :isHidden="scoringHidden(subgroup.key)"
                    :progress="insurersWithScores && insurersWithScores.length ? insurersWithScores[insIndex] && insurersWithScores[insIndex].groups[groupIndex].subgroups[subgroupIndex].precentageScore : 0"
                    :isPrintMode="true"
                  ></Precentage>
                  <!-- <Precentage
                    :progress="generateRandomScore(80, 100)"
                  ></Precentage> -->
                </td>
              </template>
            </tr>
            <template v-for="(module, modIndex) in subgroup.modules">
              <tr
                v-if="!module.submodules"
                :key="`acc-module-${modIndex}`"
                class="module-row"
              >
                <td class="module-label-col">
                  <div class="module-label">
                    {{ module.label }}
                  </div>
                </td>
                <td
                  v-for="insurer in insurers"
                  :key="insurer.key"
                  class="property-value-col"
                  :class="[
                    {'property-value-col--disabled': insurer.disabled},
                    (isQuoteExpiredMixin(insurer.key) || isRecommendationDeclinedMixin(insurer.key)) && isOffer ? 'insurer--recommendation-declined' : ''
                  ]"
                >
                  <div class="property-value">
                    <svg
                      v-if="isAvailable(insurer, module.key)"
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 16.42.79 10.21l2.83-2.83L7 10.77 16.88.88l2.83 2.83L7 16.42Z"
                        fill="#51BC7E"
                      />
                    </svg>
                    <div
                      v-else-if="isOptional(insurer, module.key)"
                      class="optional-radio"
                    >
                      <input
                        class="optional"
                        type="checkbox"
                        :value="module.key"
                        v-model="inputs.selectedModules[insurer.key]"
                        v-on:change="optionalChanged(insurer.key, module.key)"
                        :id="insurer.key + '-' + module.key"
                      />
                      <label :for="insurer.key + '-' + module.key">
                        <img width="24" class="print-opt-checked" src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/check_optional_selected.svg" alt="opt-check">
                      </label>
                      <div class="selected-module-title text-sm">
                        <template
                          v-if="
                            inputs.selectedModules[insurer.key] && inputs.selectedModules[insurer.key].includes(
                              module.key
                            )
                          "
                          >abwählbar</template
                        >
                        <template v-else>anwählbar</template>
                      </div>
                    </div>
                    <template v-else-if="insurer.properties[module.key] ? insurer.properties[module.key].state === 'info' : ''"></template>
                    <svg
                      v-else
                      width="16"
                      height="9"
                      viewBox="0 0 16 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 4H0V0h16" fill="#31A9E0" />
                    </svg>
                    <div
                      class="note"
                      v-if="
                        insurer &&
                        insurer.properties &&
                        insurer.properties[module.key] &&
                        insurer.properties[module.key].note &&
                        !compressedMode
                      "
                      v-html="getNote(insurer.properties[module.key])"
                    ></div>
                  </div>
                </td>
              </tr>
              <template v-else>
                <tr
                  :key="`acc-module-${modIndex}`"
                  class="module-row acc-child__row acc-child__row-header"
                >
                  <th class="acc-child__header">
                    <div class="module-label">
                      {{ module.label }}
                    </div>
                  </th>
                </tr>
                <tr
                  v-for="(submodule, submodIndex) in module.submodules"
                  :key="`acc-module-${modIndex}-submodule-${submodIndex}`"
                  class="acc-child__row">
                  <td class="module-label-col">
                    <div class="module-label tb-cell">
                      {{ submodule.label }}
                    </div>
                  </td>
                  <td
                    v-for="insurer in insurers"
                    :key="insurer.key"
                    class="property-value-col"
                    :class="[
                      {'property-value-col--disabled': insurer.disabled},
                      (isQuoteExpiredMixin(insurer.key) || isRecommendationDeclinedMixin(insurer.key)) && isOffer ? 'insurer--recommendation-declined' : ''
                    ]"
                  >
                    <div class="property-value tb-cell">
                      <svg
                        v-if="isAvailable(insurer, submodule.key)"
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 16.42.79 10.21l2.83-2.83L7 10.77 16.88.88l2.83 2.83L7 16.42Z"
                          fill="#51BC7E"
                        />
                      </svg>
                      <div
                        v-else-if="isOptional(insurer, submodule.key)"
                        class="optional-radio"
                      >
                        <input
                          class="optional"
                          type="checkbox"
                          :value="submodule.key"
                          v-model="inputs.selectedModules[insurer.key]"
                          v-on:change="optionalChanged(insurer.key, submodule.key)"
                          :id="insurer.key + '-' + submodule.key"
                        />
                        <label :for="insurer.key + '-' + submodule.key">
                          <img width="24" class="print-opt-checked" src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/checkout/images/check_optional_selected.svg" alt="opt-check">
                        </label>
                        <div class="d-md-block text-sm">
                          <template
                            v-if="
                              inputs.selectedModules[insurer.key] && inputs.selectedModules[insurer.key].includes(
                                submodule.key
                              )
                            "
                            >abwählbar</template
                          >
                          <template v-else>anwählbar</template>
                        </div>
                      </div>
                      <svg
                        v-else
                        width="16"
                        height="9"
                        viewBox="0 0 16 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M16 4H0V0h16" fill="#31A9E0" />
                      </svg>
                      <div
                        class="note"
                        v-if="
                          insurer &&
                          insurer.properties &&
                          insurer.properties[submodule.key] &&
                          insurer.properties[submodule.key].note &&
                          !compressedMode
                        "
                        v-html="getNote(insurer.properties[submodule.key])"
                      ></div>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </tbody>
    </table>
  </div>
</template>

<script>
import Precentage from '../block/Precentage'
import tariffsWithScanMixin from '../../../mixins/tariffsWithScanMixin.js'

export default {
  components: {
    Precentage
  },

  mixins: [tariffsWithScanMixin],

  props: {
    insurers: Array,
    inputs: Object,
    isAvailable: Function,
    isOptional: Function,
    getNote: Function,
    optionalChanged: Function,
    compressedMode: Boolean,
    isOffer: Boolean,
    insuranceProperties: Object,
  },

  computed: {
    isScoreVisible: {
      get() {
        return this.$store.state.checkout.application.isScoreVisible
      },
      set(newValue) {
        this.$store.commit('SET_SCORE_VISIBILITY', newValue)
      }
    },
    insurersWithScores() {
      return this.$store.getters.getInsurersWithScoresSorted
    }
  },

  methods: {
    generateRandomScore(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    setPrintMode() {
      this.$store.commit('SET_PRINT_MODE', true)
    },
    unsetPrintMode() {
      this.$store.commit('SET_PRINT_MODE', false)
    },
    scoringHidden(key) {
      return key === 'zusatzinformation' || key === 'addon'
    }
  },

  mounted() {
    window.addEventListener('beforeprint', this.setPrintMode);
    window.addEventListener('afterprint', this.unsetPrintMode);
  },

  destroyed() {
    window.removeEventListener('beforeprint', this.setPrintMode);
    window.removeEventListener('afterprint', this.unsetPrintMode);
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

table {
  border-collapse: separate;
}

table, tbody, tr, td, .module-label {
  // height: 100%;
}

.group-table,
.prop-body {
  display: block;
}

.printable-properties:not(.no-score) {
  .subgroup-label-col {
    background: #adddf3;
  }
  // width: 100vh;
}

.group-table {
  width: 100%;
  table-layout: fixed;
  // -webkit-column-break-inside: avoid;
  // page-break-inside: avoid;
  // break-inside: avoid;

  & + & {
    margin-top: 0.5rem;
  }
}

.group-table thead {
  position: relative;
  page-break-after: avoid;
  break-after: avoid;
}

.table-heading-row {
  // position: absolute;
  padding: 6px 10px;
  background: inherit;
  color: #fff;
  width: 100vw;
  border-radius: 0.25rem;
  z-index: 1;
  page-break-after: avoid;
  margin-bottom: 0.15cm;
  page-break-after: avoid;
  break-after: avoid;

  th {
    border-radius: 0.25rem;
    // padding-bottom: 0.2cm;
  }

  .table-heading {
    background: inherit;
    padding: 6px 10px;
    border-radius: 0.25rem;
    background: $primary;
    width: 25.4cm;
  }
}

.group-table .prop-body table, .group-table .prop-body table > tbody {
  // height: fit-content;
  // height: 100%;
}

.group-table .prop-body table > tbody {
  // transform: translateX(-0.2cm);
}

.group-table .prop-body table:first-child tbody tr:first-child td {
  // padding-top: 42px;
  // padding-top: 6px;
}

.prop-body table {
  table-layout: fixed;
  width: 100%;
  margin-top: 0;

  border-spacing: 0.2cm;
}
.prop-body tr td {
  // padding: 2px 4px 2px 0;

  border-radius: 0.25rem;
  // background: #f5fbfd;
}
.prop-body tr td:last-child {
  padding-right: 0;
}

.prop-body tr:last-child td {
  padding-bottom: 4px;
}

.subgroup-label-col {
  min-width: 340px;
  width: 340px;
  vertical-align: top;
  page-break-after: avoid;
  break-after: avoid;
}

.subgroup-label {
  padding: 6px 10px;
  background: #adddf3;
  border-radius: 0.25rem;
  font-weight: bold;
  margin-right: 2px;
  -webkit-column-break-after: avoid;
  page-break-after: avoid;
  break-after: avoid;
}

.module-label-col {
  height: 100%;
  // min-width: 280px;
  // width: 30%;
}

.module-label {
  padding: 6px 10px;
  height: 100%;
  border-radius: 0.25rem;
  font-weight: bold;
  margin-right: 2px;
}

.module-row {
  // width: 100vw;
  // height: 100%;
  // min-height: fit-content;
   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.acc-child__header {
  -webkit-column-break-after: avoid;
  page-break-after: avoid;
  break-after: avoid;
}


.module-row:nth-child(even) {
  .module-label-col,
  .property-value-col,
  .acc-child__header > .module-label {
    background: #e0f2fa;

  }
}

.module-row:nth-child(odd) {
  .module-label-col,
  .property-value-col,
  .acc-child__header > .module-label {
    background: #f5fbfd;
  }
}

.propery-name-col,
.table-heading {
  // position: sticky;
  // left: 0;
  // width: 402px;
  // background: #fff;
}

.property-value-col {
  // display: inline-flex;
  // padding: 2px;

  // height: 100%;
  width: 17.5%;
  min-width: 155px;
  max-width: 155px;

  &--disabled {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

.property-value {
  // ! d:flex & h:100% break pdf pages
  // display: inline-flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // gap: 0.5rem;
  padding: 0.5rem 0.25rem;
  // width: 165px;
  width: 100%;
  // height: 100%;
  border-radius: 0.25rem;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  word-break: break-word;
  text-align: center;

  .note {
    text-align: center;
    height: fit-content;
  }


  & > * + * {
    margin-top: 0.25cm;
  }
}

.precentage-col {
  padding: 0;
  // width: 17.5%;

  &--disabled {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

.precentage-col .precentage {
  height: 38px;
}

.optional-radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .optional {
    background-image: none;

    &:checked + label .print-opt-checked {
      display: block;
    }
  }

  .print-opt-checked {
    display: none;
  }
}

.no-score .subgroup-label {
  width: 200vw;
}

.is-offer {
  tr, td {
    // -webkit-column-break-inside: avoid;
    // page-break-inside: avoid;
    // break-inside: avoid;
  }

  ::v-deep .first-col-wrapper {
    width: 340px;
    max-width: 340px;
  }

  &.printable-properties {
    // width: 100vw;
  }

  table {
    table-layout: fixed;

    // border-spacing: 0.5cm;
  }

  .prop-body table {
    width: 100%;
    // width: auto;
  }

  .prop-body table tbody {
    width: 100%;
  }

  .group-table {
    // width: auto;
  }

  .prop-body tr {
    width: 100vw;
  }

  .table-heading-row {
    width: 100vw;
    position: relative;
    background: inherit;
    color: #fff;
    // width: 100vh;
    z-index: 1;
    page-break-after: avoid;

    th {
      padding-bottom: 0.25rem;
    }

    .table-heading {
      background: $primary;
      padding: 6px 10px;
      border-radius: 0.25rem;
      width: 27.9cm;
    }
  }

  &.no-score .subgroup-label {
    width: 27.6cm;
  }
}

.acc-child__row {
  &.acc-child__row-header {
    // display: flex;
    // width: 100%;
    // display: table-caption;
  }
  // display: flex;
  &:nth-child(odd) {
    .property-value-col,
    .module-label-col {
      background: #ececec;
    }
  }
  &:nth-child(even) {
    .property-value-col,
    .module-label-col {
      background: #f6f6f6;
    }
  }

  .module-label-col {
    // padding-left: 1rem;
    padding-right: 6px;
  }
}

th.acc-child__header .module-label {
  min-width: 27.6cm;
  // max-width: 31.35cm;
  // min-width: 31.35cm;
}

.insurer--recommendation-declined {
  filter: grayscale(100%);
  opacity: 0.5;
}

.selected-module-title {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  @media print {
    display: block;
  }
}
</style>
