import { render, staticRenderFns } from "./Step4.vue?vue&type=template&id=1fba9ab0&scoped=true"
import script from "./Step4.vue?vue&type=script&lang=js"
export * from "./Step4.vue?vue&type=script&lang=js"
import style0 from "vue-tel-input/dist/vue-tel-input.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./Step4.vue?vue&type=style&index=1&id=1fba9ab0&prod&lang=scss&scoped=true"
import style2 from "./Step4.vue?vue&type=style&index=2&id=1fba9ab0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fba9ab0",
  null
  
)

export default component.exports