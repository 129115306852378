// list is the list if insurers to be sortedIndex
// criteria is the criteria to sort by
// alternativeData is to distinguish between twoi different data structures
// EXPORT
import Logger from '../src/helpers/Logger'

export default {

  getSortedInsurers(list, criteria, alternativeData = false) {
    return list.sort((a, b) => {
      // insurer data come in two different forms, we have to treat both cases
      // so we remap values based on whats available
      const aKey = alternativeData ? String(a.insurer.label).toLowerCase() : String(a.label).toLowerCase()
      const bKey = alternativeData ? String(b.insurer.label).toLowerCase() : String(b.label).toLowerCase()
      const aScore = alternativeData ? a.sumScore : a.score
      const bScore = alternativeData ? b.sumScore : b.score
      const aPrice = alternativeData ? a.grossPrice : a.prices.gross
      const bPrice = alternativeData ? b.grossPrice : b.prices.gross
      const aQuestionsCount = alternativeData ? a.insurer.questionsCount : a.questionsCount
      const bQuestionsCount = alternativeData ? b.insurer.questionsCount : b.questionsCount

      switch (criteria) {
        case 'abc':
          if(!aKey || !bKey) {
          }
          if (aKey < bKey) {
            return -1
          }
          if (aKey > bKey) {
            return 1
          }
          return 0
        case 'score':
          // if(!aScore || !bScore) {
          // }
          if (aScore > bScore) {
            return -1
          } else if (aScore < bScore) {
            return 1
          }
          return 0
        case 'price':
          if(!aPrice || !bPrice) {
            Logger('price is missing for ', aPrice ? bPrice : aPrice, 'insurer key: ', aKey ? bKey : aKey)
          }
          if (aPrice < bPrice) {
            return -1
          } else if (aPrice > bPrice) {
            return 1
          }
          return 0
        case 'price-score-ratio':
          if(!aPrice || !bPrice) {
            Logger('price is missing for ', aPrice ? bPrice : aPrice, 'insurer key: ', aKey ? bKey : aKey, 'error')
          }

          const aRatio = aPrice / aScore
          const bRatio = bPrice / bScore

          if (aRatio < bRatio) {
            return -1
          } else if (aRatio > bRatio) {
            return 1
          }
          return 0
        case 'questions-amount':
          if (aQuestionsCount === bQuestionsCount) {
            return aKey.localeCompare(bKey);
          } else if(!aQuestionsCount || !bQuestionsCount) {
            Logger('questionsCount is missing for ', aKey, 'error')
            return 0
          } else {
            return aQuestionsCount - bQuestionsCount
          }
      }
    })
  },
}
