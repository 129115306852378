<template>
  <div class="insurers" :class="{'insurers-space': !isOffer}">
      <div
        class="company insurer"
        :class="[
          isOffer ? 'company--offer' : '',
          {'insurer--disabled': insurer.disabled, 'insurer--recommended': insurer.key === recommendedInsurer},
          `insurer--${insurers.length}`,
          (isQuoteExpiredMixin(insurer.key) || isRecommendationDeclinedMixin(insurer.key)) && isOffer ? 'insurer--recommendation-declined' : '',
          isOnlyImages ? 'insurer__smaller-scale company--only-images' : ''
        ]"
        v-for="(insurer, iIndex) in innerInsurers"
        :key="('insurer' + insurer.key)"
      >
        <template v-if="!isOnlyImages">
          <div v-if="synaxon && synaxon.tooltip" class="insurer__top-badge d-print-none">
            <div
              class="insurer__banner insurer__banner--synaxon"
              :id="'synaxon-tooltip' + iIndex"
            >
              {{ synaxon.tooltip.trigger }}
            </div>
            <b-tooltip
              :target="'synaxon-tooltip' + iIndex"
              triggers="hover"
              custom-class="custom-tooltip"
            >
              <div class="mb-3">
                Der im Versicherungsschein vereinbarte Selbstbehalt reduziert sich
                je Schadenfall um 50%, minimal jedoch auf € 500 unter den
                nachfolgenden Voraussetzungen im Rahmen der Zusammenarbeit mit der
                einsnulleins GmbH und der IT-Partner der SYNAXON AG. Durchführung
                eines Desktop-Managements und Server-Managements mit den folgenden
                Inhalten:
              </div>
              <ul>
                <li>
                  Tägliche Schwachstellen-Analyse betriebskritischer Parameter
                </li>
                <li>Monatlicher Bericht über den Systemstatus</li>
                <li>Fernwartungssoftware</li>
                <li>
                  Fortlaufende Überprüfung der DatensicherungInventarisierung
                  eingesetzter Hard- & Software
                </li>
                <li>Überprüfung der Aktualität der Virensignaturen</li>
                <li>Installation sicherheitsrelevanter Patches</li>
                <li>Aktive Alarmierung bei Sicherheitsbedrohungen</li>
                <li>Bereinigung temporärer Dateien</li>
                <li>Virenschutz</li>
                <li>Telefonischem IT-Support Mo-Fr 8:00 - 18:00 Uhr</li>
                <li>Kostenlosem Remote-Support bei Störungen</li>
                <li>
                  Leistungsüberwachung der Server Überprüfung der Windows-Dienste.
                </li>
              </ul>
            </b-tooltip>
          </div>
          <b-form-checkbox
            v-if="isBroker && mode !== 'offer'"
            class="cd-control checkbox-accent checkbox--center d-print-none"
            :id="`offerCheck-${insurer.key}`"
            :name="`offerCheck-${insurer.key}`"
            :value="insurer.key"
            :disabled="
              (offerSelectionInner.length >= maxOffers &&
              offerSelectionInner.indexOf(insurer.key) === -1) ||
              insurer.disabled
            "
            v-model="offerSelectionInner"
          >
          </b-form-checkbox>
          <div
            class="insurer__image"
            :class="[
              'insurer__image--' + getInsurerKey(insurer.key),
              {'insurer__image--has-banner': hasBanner(insurer)}
            ]"
          >
            <div
              class="insurer__banner insurer__banner--artus d-print-none"
              v-if="shouldShowArtusBanner(insurer)"
            >
              ARTUS Sonderbedingungen
            </div>
            <img :src="getInsurerLogoPath(insurer)" :alt="insurer.label" />
          </div>
          <div v-if="isOffer && hasBanner(insurer)" class="insurer__badge-box">
            <b-badge
                v-for="bnr in getBanner(insurer)" :key="bnr.text"
                class="custom-badge offer-badge insurer__badge"
                @click="setModalBody(bnr.long, bnr.text === 'Sideletter*')"
                :class="[
                  getBannerClass(insurer),
                  getLabelStyleMixin(bnr.style),
                  getLabelColorMixin(bnr),
                  isRecommendationDeclinedMixin(insurer.key) && isOffer && bnr.text.includes('Indikation') ? 'insurer__banner--hide' : ''
                ]"
            >
              {{ bnr.text }}
            </b-badge>
          </div>
          <div v-if="(!isOffer && hasBanner(insurer)) || checkoutButtonIsVisible" class="insurer__bottom d-print-none">
            <div v-if="!isOffer && hasBanner(insurer)"
              class="insurer__badge-box">
              <b-badge
                v-for="bnr in getBanner(insurer)" :key="bnr.text"
                class="custom-badge insurer__badge"
                @click="setModalBody(bnr.long, bnr.text === 'Sideletter*')"
                :class="[
                  getBannerClass(insurer),
                  getLabelStyleMixin(bnr.style),
                  getLabelColorMixin(bnr)
                ]"
              >
                {{ bnr.text }}
              </b-badge>
            </div>
            <div class="action d-print-none">
              <ButtonComponent
                v-if="!tariffHasScanMixin(insurer.key)"
                class="icon-link"
                btn-style="link-primary"
                :icon="
                  checkoutButtonIsVisible
                    ? {
                        name: 'arrow-full-right',
                        position: 'right'
                      }
                    : null
                "
                @click.native="selectInsurer(insurer)"
                :behavior="'button'"
                :disabled="insurer.disabled"
                :type="isAffiliate ? 'button': 'submit'"
              >
                <template v-if="checkoutButtonIsVisible">
                  <span class="d-none d-lg-inline">{{ order1 }} </span>
                  <span>{{ order2 }}</span>
                </template>
              </ButtonComponent>
            </div>
          </div>
          <div v-else-if="!isOffer" class="insurer__bottom insurer__bottom--empty"></div>
        </template>
        <template v-else>
          <div
            class="insurer__image insurer__image--smaller"
            :class="[
              'insurer__image--' + getInsurerKey(insurer.key),
              {'insurer__image--has-banner': hasBanner(insurer)}
            ]"
          >
            <img :src="getInsurerLogoPath(insurer)" :alt="insurer.label" />
          </div>
        </template>
      </div>
  </div>
</template>

<script>
import ButtonComponent from '../ui/ButtonComponent.vue'
import Modal from '../../../Modal';
import { get as getValue } from 'lodash'
import bannersLogic from '../../../mixins/banners.js'
import tariffsWithScanMixin from '../../../mixins/tariffsWithScanMixin.js'


export default {
  components: {
    Modal,
    ButtonComponent
  },
  mixins: [bannersLogic, tariffsWithScanMixin],
  props: {
    insurers: {
      type: Array,
      default() {
        return []
      }
    },
    synaxon: Object,
    checkoutButtonIsVisible: Boolean,
    affiliateBroker: Object,
    // getBanner: Function,
    // getBannerClass: Function,
    order1: String,
    order2: String,
    isBroker: Boolean,
    isOffer: {
      type: Boolean,
      default: false,
      required: false
    },
    mode: String,
    offerSelection: Array,
    maxOffers: Number,
    isAffiliate: Boolean,
    isOnlyImages: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      innerInsurers: this.insurers,
      recommendedInsurer: this.$store.state.offer?.offer?.insurer
    }
  },
  computed: {
    offerSelectionInner: {
      get() {
        return this.offerSelection
      },
      set(newValue) {
        this.$emit('updateOfferSelection', newValue)
      }
    },
  },
  watch: {
    offerSelectionInner(newValue) {
      this.$emit('updateOfferSelection', newValue)
    },
    insurers: {
      handler (newValue) {
        this.innerInsurers = newValue
      },
      deep: true
      // if(this.mode === 'offer') {
      //   this.innerInsurers = this.insurers
      // }
    }
  },
  mounted() {
  },
  methods: {
    // getLabelColor(banner) {
    //   return banner.color === 'red' ? 'insurer__badge--red' :
    //           banner.color === 'yellow' ? 'insurer__badge--yellow' :
    //           banner.color === 'gray' ? 'insurer__badge--gray' : null;
    // },

    setModalBody(content, isVisibleHeader) {
      this.$emit('setModalBody', content, isVisibleHeader);

      return this;
    },

    getInsurerKey(key) {
      return key.indexOf('victor') > -1 ? 'victor' : key
    },

    shouldShowArtusBanner(insurer) {
      const isArtus =
        this.$store.state.user &&
        this.$store.state.user.brokerAgency &&
        this.$store.state.user.brokerAgency.key &&
        this.$store.state.user.brokerAgency.key.includes('artus')
      const offerKey = getValue(this.$store.state, 'offer.offer.key')
      const isArtusCampaign = offerKey && offerKey.includes('artus')

      return (
        (isArtus || isArtusCampaign) &&
        ['aig', 'hiscox'].indexOf(insurer.key) > -1
      )
    },

    getInsurerLogoPath(insurer) {
      return insurer.logoUrl
    },

    // getLabelStyle(data) {
    //   if ( data != null && this.affiliateBroker?.agency == data) {
    //     return `insurer__label--${data}`
    //   }
    // },

    selectInsurer(insurer) {
      this.$emit('selectInsurer', insurer)
    },

    forceUpdateInsurers () {
      this.innerInsurers = this.insurers
    }
  }
}
</script>

<style lang="scss">
@import '../../../scss/variables';

.insurer__modal {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    padding-left: 16px;
  }

  li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 7px;
    background: $orangeTone;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '../../../scss/mixins';
@import '../../../scss/tooltip';
@import '../../../scss/form';
@import '../../../scss/button';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../scss/print/insurers.scss';

.insurers {
  display: flex;
  gap: 0.5rem;

  @include media-breakpoint-down(sm) {
    gap: 0.5rem;
  }

  .modal-header {
    h3 {
      font-size: 29px;
      text-align: center;
      margin: 0 auto;
      font-weight: 900;
      color: $blackColor
    }
  }
}

.insurers-space {
  @include media-breakpoint-down(sm) {
    margin-left: 298px;
  }
}

.company {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 3rem;
  // gap: 1rem;
  width: 290px;

  &--only-images {
    padding: 0.2rem 4rem
  }

  @include media-breakpoint-down(md) {
    padding: 1rem;
  }

  &--offer {
    justify-content: center;

    .insurer__image {
      position: relative;
      margin-bottom: 0;

      img {
        // width: auto;
      }

      &--has-banner {
        margin-bottom: 1.5rem;
      }
    }

    .insurer__badge-box {
      z-index: 101;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .insurer__badge {
      position: static;
      bottom: auto;
      margin: 0;
      cursor: pointer;
      white-space: wrap;

      & + .insurer__badge {
        margin-top: 6px;
      }

      &--red {
        background: rgb(220, 53, 69);
      }

      &--yellow {
        background: rgb(232, 175, 8);
      }

      &--gray {
        background: rgb(134, 142, 150);
      }
    }

  }
}

.logo img {
  // min-height: 65px;
  max-width: 120px;
}

.badge {
  margin-bottom: 0px;
}

.insurer {
  position: relative;

  &__smaller-scale {
    transform: scale(0.8);
  }

  &__label--vodafone {
    background: #e60000 !important;
  }

  &__head {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__banner {
    padding: 2px;
    width: 100%;
    background: $primary;
    color: #fff;
    font-weight: bold;
    font-size: 0.8rem;
    text-align: center;

    &--warning {
      background: $redTone;
    }

    &--green {
      background: $greenTone;
    }

    &--artus {
      background: #ffb200;
      position: absolute;
      top: -20px;
    }

    &--synaxon {
      background: $secondary;
    }

    &--hide {
      display: none;
    }
  }

  &__image {
    min-height: 60px;
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: auto;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1.5rem;
    }

    img {
      margin: 0 auto;
      display: block;
      // width: 100%;
      width: auto;
      max-width: 85px;
      max-height: 100%;

      @include media-breakpoint-up(md) {
        max-width: 100px;
        max-height: 65px;
      }
    }

    &--aig {
      img {
        width: 100px;
      }
    }

    &--markel {
      img {
        max-height: 60px;
        width: auto;
      }
    }

    &--hdi {
      img {
        height: 40px;
      }
    }

    &--hdi-vodafone {
      img {
        height: 40px;
      }
    }

    &--axa {
      img {
        height: 40px;
      }
    }

    &--allianz {
      img {
        min-width: 120px;
      }
    }

    &--victor {
      img {
        height: 60px;
      }
    }

    &--corvus-rc1-sideletter,
    &--corvus-rc2-sideletter,
    &--corvus-rc3-sideletter,
    &--corvus-rc4-sideletter,
    &--corvus-rc5-sideletter,
    &--corvus-rc1-standard,
    &--corvus-rc2-standard,
    &--corvus-rc3-standard,
    &--corvus-rc4-standard,
    &--corvus-rc5-standard {
      img {
        height: 65px;
        max-width: 200px;
      }
    }
  }

  &__bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 0.75rem;

    &--empty {
    }

    .insurer__badge-box {
      display: flex;
      align-items: center;
      align-content: center;
      flex-direction: column;

    }
    .insurer__badge {
      position: static;
      bottom: auto;
      margin: 0;
      cursor: pointer;

      & + .insurer__badge {
        margin-top: 6px;
      }


      &--red {
        background: rgb(220, 53, 69);
      }

      &--yellow {
        background: rgb(232, 175, 8);
      }

      &--gray {
        background: rgb(134, 142, 150);
      }
    }

    .action {
      margin-top: auto;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  &__badge {
    position: absolute;
    bottom: 2rem;
    background: linear-gradient(180deg, rgba(81,188,125,1) 0%, rgba(118,223,161,1) 35%, rgba(81,188,125,1) 100%);
    z-index: 4;

    @include media-breakpoint-down(sm) {
      bottom: 18px;
    }
  }

  &__select {
    height: 50px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    &__top {
      height: 50%;
    }

    &__bottom {
      height: 50%;
    }
  }

  &__offer-check {
    height: 24px;
  }

  &--disabled {

    & > *:not(.insurer__bottom) {
      opacity: 0.5;
      filter: grayscale(100%);
    }

    .insurer__badge {
      // position: static;
      background: $redTone;
    }

    .action {
      opacity: 0.5;
      filter: grayscale(100%);
    }
  }

  .custom-checkbox {
    margin-bottom: 4rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }
}
</style>
