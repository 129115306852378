import Start from './src/components/Start'
import MultiStep from './src/components/MultiStep'
import Step1a from './src/components/Step1a'
import Step1b from './src/components/Step1b'
// import Step1c from './src/components/Step1c'
import Produktvergleich from './src/components/Produktvergleich'
import Produktauswahl from './src/components/Produktauswahl'
import Step2 from './src/components/Step2'
import Step3 from './src/components/Step3'
import Step4 from './src/components/Step4'
// import StepLegalQ1 from './src/components/StepLegalQ1'
// import StepLegalQ2 from './src/components/StepLegalQ2'
import Success from './src/components/Success'

import dateFilter from './src/filter/dateFilter'
import currencyFilter from './src/filter/currencyFilter'
import checkoutStore from './src/store'
import Logger from './src/helpers/Logger'


require('./src/scss/base.scss')
let alreadyMounted = false;
let dataInitialised = false;

export default {
  install(Vue, {store, router, mode, urlPrefix = '', style, isOffer}) {
    Vue.prototype.$scrollToTop = () => window.scrollTo(0,0);
    Vue.mixin({
      mounted() {
        const parentRouter = router || this.$router
        if (alreadyMounted) {
          return
        }

        alreadyMounted = true;
        // register store
        store.registerModule('checkout', checkoutStore(mode, style, isOffer), {preserveState: !!store.state.checkout});
        if (!dataInitialised) {
          store.commit('RESET_INIT_STATUS', false); // set up flag for loading indicators
        }
        
        // add filter
        Vue.filter('date', dateFilter);
        Vue.filter('currency', currencyFilter);

        parentRouter.beforeEach( async (to, from, next) => {
          function isStrIncludesOneOf(str, arrayOfSubstr) {
            return arrayOfSubstr.some((substr) => {
              return str.indexOf(substr) > -1
            })
          }

          if (isStrIncludesOneOf(to.path, ['/versicherung/'])) {
            this.$scrollToTop()
          }

          // check getters
          // this is not great solution, needs some re-work
          if (isStrIncludesOneOf(to.path, ['/versicherung/']) && !dataInitialised) {
            store.commit('SET_INIT_STATUS', 'versicherung'); // set up flag for loading indicators
            
            // this speeds up the initialm load
            if (isStrIncludesOneOf(to.path, ['/versicherung/1b/ihr-unternehmen/turnover']) ||
                isStrIncludesOneOf(to.path, ['/versicherung/1a/ihr-unternehmen/branche'])) {
              
              Logger('Initial data load skipped for faster load');
              await store.dispatch('GET_PARTIAL_INITIAL_DATA');
              // non-awaiting call
              dataInitialised = store.dispatch('GET_INITIAL_DATA');
            } else {
              dataInitialised = await store.dispatch('GET_INITIAL_DATA');
            }
            // dataInitialised = await store.dispatch('GET_INITIAL_DATA');
          }

          if (to.path.indexOf('/versicherung/') > -1 && store.state.checkout.application.inputs.type === 'legal') {
            store.dispatch('UPDATE_INPUT_VALUES', {
              insurer: null,
              deductible: 1000,
              answers: {},
              legal: {}
            });
          }

          if (to.path.indexOf('/rechtsschutzversicherung/') > -1 && store.state.checkout.application.inputs.type === 'cyber') {
            store.dispatch('UPDATE_INPUT_VALUES', {
              insurer: null,
              deductible: 250,
              answers: {}
            });
          }

          // check if countryInterface is setted outside of checkout
          const countryInterface = store.state.checkout.application.countryInterface
          if (!countryInterface) {
            store.commit('SET_COUNTRY_INTERFACE', {setFrom: 'checkout'})
          }

          next();
        });

        // add routes
        parentRouter.addRoutes([
          {
            name: 'Start',
            type: 'router',
            path: `${urlPrefix}/versicherung`,
            component: Start,
            meta: {
              title: 'Get Started | CyberDirekt',
              isSignUp: true,
              requiresAuth: false,
              hideNavigation: true
            }
          },
          {
            name: 'Start Dispather',
            type: 'router',
            path: `${urlPrefix}/versicherung/:id`,
            component: Start,
            meta: {
              title: 'Get Started | CyberDirekt',
              isSignUp: true,
              requiresAuth: false,
              hideNavigation: true
            },
            children: [
              {
                path: 'ihr-unternehmen',
                component: MultiStep,
                children: [
                  {
                    name: 'branche',
                    path: 'branche',
                    component: Step1a
                  },
                  {
                    name: 'turnover',
                    path: 'turnover',
                    component: Step1b
                  }
                ]
              },
              {
                path: 'produktauswahl',
                name: 'produktauswahl',
                component: Produktauswahl
              },
              {
                path: 'produktvergleich',
                name: 'produktvergleich',
                component: Produktvergleich
              },
              {
                path: 'angebot',
                name: 'angebot',
                component: Step2
              },
              {
                path: 'check-list',
                name: 'check-list',
                component: Step3
              },
              {
                path: 'vertragsdaten',
                name: 'checkout-customer-form',
                component: Step4
              },
              {
                path: 'abschluss',
                name: 'abschluss',
                component: Success
              }
            ]
          },
          // {
          //   name: 'Start Legal',
          //   type: 'router',
          //   path: `${urlPrefix}/rechtsschutzversicherung/:id`,
          //   component: Start,
          //   meta: {},
          //   children: [
          //     {
          //       path: 'ihr-unternehmen',
          //       component: MultiStep,
          //       children: [
          //         {
          //           path: 'branche',
          //           name: 'branche',
          //           component: Step1a
          //         },
          //         {
          //           path: 'ihre-rechtsschutz',
          //           name: 'ihre-rechtsschutz',
          //           component: Step1c
          //         }
          //       ]
          //     },
          //     {
          //       path: 'risikofragen',
          //       component: MultiStep,
          //       children: [
          //         {
          //           name: 'risikofragen-1',
          //           path: '1',
          //           component: StepLegalQ1
          //         },
          //         {
          //           name: 'risikofragen-2',
          //           path: '2',
          //           component: StepLegalQ2
          //         }
          //       ]
          //     },
          //     {
          //       path: 'vertragsdaten',
          //       name: 'vertragsdaten',
          //       component: Step4
          //     },
          //     {
          //       path: 'abschluss',
          //       name: 'abschluss',
          //       component: Success
          //     }
          //   ]
          // }
        ]);
      },
      methods: {
        $getParents: function (el, parentSelector) {
          if (parentSelector === undefined) {
            parentSelector = document
          }
          var parents = []
          var p = el.parentNode

          try {
            while (p !== parentSelector) {
              var o = p
              parents.push(o)
              p = o.parentNode
            }
          } catch (err) {
            return []
          }
          parents.push(parentSelector)

          return parents
        }
      }
    })
  }
}
