import { render, staticRenderFns } from "./Step1b-Broker.vue?vue&type=template&id=002dc8ac&scoped=true"
import script from "./Step1b-Broker.vue?vue&type=script&lang=js"
export * from "./Step1b-Broker.vue?vue&type=script&lang=js"
import style0 from "./Step1b-Broker.vue?vue&type=style&index=0&id=002dc8ac&prod&lang=scss"
import style1 from "./Step1b-Broker.vue?vue&type=style&index=1&id=002dc8ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002dc8ac",
  null
  
)

export default component.exports