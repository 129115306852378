var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'is-offer': _vm.isOffer}},[_c('table',{staticClass:"print-scores",class:{
    'print-scores--price-perform-winner': _vm.isPricePerformWinner()
  }},[(_vm.isScoreVisible)?_c('tbody',[_c('tr',[_c('td',{staticClass:"first-col-td"},[_c('div',{staticClass:"first-col td-col"},[_c('div',{staticClass:"td-col__content scores-info"},[_vm._m(0),_c('p',[_vm._v("Bei Ratingkreisen mit zwei Ringen gilt:")]),_c('div',{staticClass:"text-with-dot text-sm"},[_c('svg',{attrs:{"width":"30","height":"20"}},[_c('line',{attrs:{"x1":"0","y1":"10","x2":"25","y2":"10","stroke":"#11b36e","stroke-width":"8"}})]),_c('p',[_vm._v("Exklusiver CyberDirekt Tarif")])]),_c('div',{staticClass:"text-with-dot text-sm"},[_c('svg',{attrs:{"width":"30","height":"20"}},[_c('line',{attrs:{"x1":"0","y1":"5","x2":"25","y2":"5","stroke":"#89E0AC","stroke-width":"4"}}),_c('line',{attrs:{"x1":"0","y1":"11","x2":"25","y2":"11","stroke":"#89E0AC","stroke-width":"4"}})]),_c('p',[_vm._v("Standardtarif")])]),_c('div',{staticClass:"mb-3"}),_c('div',{staticClass:"text-sm mt-1"},[_c('p',[_vm._v(" Informationen zu der Methodik unseres Leistungsvergleiches* finden Sie "),_c('ButtonComponent',{attrs:{"btnStyle":"link-primary","btn-size":"xs","font-size":"0.9rem","behavior":"externalLink","link":`${_vm.CLIENT_BASE}methodik-vergleich`}},[_vm._v(" hier")]),_vm._v(". ")],1)])])])]),_vm._l((_vm.insurers),function(insurer,insIndex){return _c('td',{key:insurer.key,staticClass:"td",class:[
          `td--${_vm.insurers.length}`,
          (_vm.isQuoteExpiredMixin(insurer.key) || _vm.isRecommendationDeclinedMixin(insurer.key)) && _vm.isOffer ? 'insurer--recommendation-declined' : ''
        ]},[_c('div',{staticClass:"td-col td-col-content",class:{'td-col--disabled': insurer.disabled}},[_c('ProgressRing',{staticClass:"enlarge-circle",attrs:{"isPrintMode":true,"radius":70,"progress":_vm.insurersWithScores[insIndex] ? _vm.insurersWithScores[insIndex].precentageScore : null,"stroke":12,"standard-score":_vm.insurersWithScores[insIndex] ? _vm.insurersWithScores[insIndex].standardScore : null}})],1)])})],2)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('b',[_vm._v("Rating")])])
}]

export { render, staticRenderFns }