<template>
  <div class="container">
    <form name="step4" id="step_form">
      <div class="row">
        <div class="col-12">
          <h1>{{ title }}</h1>
        </div>
      </div>
      <div class="row contract-details">
        <div class="col-12 col-lg-4 contract-details__item d-flex flex-column">
          <div class="contract-detail__header">
            Anbieter
          </div>
          <div class="contract-detail__content d-flex flex-column justify-content-center align-items-center">
            <div class="contract-detail__logo" :class="['contract-detail__logo--' + insurer.key]">
              <img :class="insurer.key" :src="getInsurerLogoPath(insurer)" :alt="insurer.label">
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 contract-details__item d-flex flex-column">
          <div class="contract-detail__header">
            Preis
          </div>
          <div class="contract-detail__content d-flex flex-column justify-content-center">
            <div class="contract-detail__price">
              <div>{{ price.gross | currency(2) }}</div>
              <div class="text-muted">brutto {{ getPaymentPeriod() }} <strong v-if="price.grossWithoutDiscount">im 1.
                Jahr</strong></div>
            </div>
            <div class="contract-detail__price contract-detail__price--small">
              <div class="text-muted">inkl. {{insTaxRate}}% Versicherungssteuer
                <span>= {{ price.tax | currency(2) }}</span>
              </div>
            </div>
          </div>
          <div class="contract-detail__content d-flex flex-column justify-content-center"
               v-if="price.grossWithoutDiscount">
            <div class="contract-detail__price">
              <div>{{ price.grossWithoutDiscount | currency(2) }}</div>
              <div class="text-muted">brutto {{ getPaymentPeriod() }} <strong>ab dem 2. Jahr</strong></div>
            </div>
            <div class="contract-detail__price contract-detail__price--small">
              <div class="text-muted">inkl. {{insTaxRate}}% Versicherungssteuer
                <span>= {{ price.taxWithoutDiscount | currency(2) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 contract-details__item d-flex flex-column">
          <div class="contract-detail__header">
            Details
          </div>
          <div class="contract-detail__content d-flex flex-column justify-content-center">
            <div class="d-flex contract-detail__row">
              <div>Vertragsdauer</div>
              <div>1 Jahr</div>
            </div>
            <div class="d-flex contract-detail__row">
              <div>Versicherungssumme</div>
              <div>{{ getInsuredSum(inputs.insuredSum) | currency(0) }}</div>
            </div>
            <!-- <div class="d-flex contract-detail__row" v-else>
              <div>Betriebsgröße</div>
              <div>{{ inputs.employees }} Mitarbeiter</div>
            </div> -->
            <div class="d-flex contract-detail__row">
              <div>Selbstbehalt</div>
              <div>{{ inputs.deductible | currency(0) }}</div>
            </div>
          </div>
        </div>
      </div>
      <ContractDataForm
        class="mb-4"
        :insurer="insurer"
        :isCompanyMax60Alerted="isCompanyMax60Alerted"
        @companyMax60="handleCompanyMax60"
      />
      <div class="row">
        <div class="col-12 mb-4 hr-wrapper">
          <hr>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-5">
              <h2 v-if="!isInvoicePaymentPossible">
                SEPA-Lastschrift</h2>
              <h2 v-else>Abrechnung</h2>
            </div>
            <div class="col-12 col-md-7">
              <div class="row" v-if="isInvoicePaymentPossible">
                <div class="col-12 col-md-6">
                  <label class="cd-label">Zahlung via</label>
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input"
                          type="radio"
                          id="radioInvoice"
                          v-model="inputs.paymentMethod"
                          value="invoice"
                          name="paymentMethod">
                    <label class="custom-control-label" for="radioInvoice">Rechnung</label>
                  </div>
                  <div v-if="!getSEPA().isDisabled" class="custom-control custom-radio">
                    <input class="custom-control-input"
                          type="radio"
                          id="radioDirectDebit"
                          v-model="inputs.paymentMethod"
                          value="directDebit"
                          name="paymentMethod">
                    <label class="custom-control-label" for="radioDirectDebit">SEPA-Lastschrift</label>
                  </div>
                </div>
              </div>
              <transition name="fade">
                <div v-if="!getSEPA().isDisabled && inputs.paymentMethod === 'directDebit'">
                  <div class="row">
                    <div class="col-12 col-md-6 cd-control form-group" :class="[getFieldClass('sepaCompanyName'), {'is-danger': inputs.insurer && inputs.insurer.includes('victor') && inputs.sepa.company.length > 60}]">
                      <label for="sepaCompanyName">Vollst&auml;ndiger Firmenname *</label>
                      <input class="form-control"
                            type="text"
                            id="sepaCompanyName"
                            name="sepaCompanyName"
                            v-model="inputs.sepa.company"
                            v-validate="`required`"
                            placeholder="Muster GmbH &amp; Co. KG">
                    </div>
                    <div class="col-12 col-md-6 form-group d-flex align-items-end justify-content-end">
                      <button class="btn btn-secondary btn-block" type="button" @click="copyAddress()">Daten von
                        oben
                        &uuml;bernehmen
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-6 form-group" :class="getFieldClass('sepaFirstname')">
                      <label for="sepaFirstname">Vorname</label>
                      <input class="form-control"
                            type="text"
                            id="sepaFirstname"
                            name="sepaFirstname"
                            v-model="inputs.sepa.firstname"
                            placeholder="Ihr Vorname">
                    </div>
                    <div class="col-12 col-sm-6 form-group" :class="getFieldClass('sepaLastname')">
                      <label for="sepaLastname">Nachname</label>
                      <input class="form-control"
                            type="text"
                            id="sepaLastname"
                            name="sepaLastname"
                            v-model="inputs.sepa.lastname"
                            placeholder="Ihr Nachname">
                    </div>
                  </div>
                  <div class="row">
                    <div class="cd-control form-group col-12" :class="getFieldClass('sepaStreet')">
                      <label for="sepaStreet">Straße (inkl. Hausnummer) *</label>
                      <input class="form-control"
                            type="text"
                            id="sepaStreet"
                            name="sepaStreet"
                            v-validate="'required|min:1'"
                            v-model="inputs.sepa.street"
                            placeholder="Musterstrasse 1">
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-12 col-sm-6" :class="getFieldClass('sepaPostal')">
                      <label for="sepaPostal">PLZ *</label>
                      <input class="form-control"
                            type="text"
                            id="sepaPostal"
                            name="sepaPostal"
                            v-validate="postalValidation()"
                            v-model="inputs.sepa.postal"
                            @beforeinput="preventInputHandler($event, {numeric: true})"
                            :placeholder="postalPlaceholder()">
                    </div>
                    <div class="form-group col-12 col-sm-6" :class="getFieldClass('sepaCity')">
                      <label for="sepaCity">Stadt *</label>
                      <input class="form-control"
                            type="text"
                            id="sepaCity"
                            name="sepaCity"
                            v-validate="'required|min:1'"
                            v-model="inputs.sepa.city"
                            :placeholder="cityPlaceholder()">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6 form-group" :class="getFieldClass('iban')">
                      <label for="iban">IBAN *</label>
                      <the-mask
                          id="iban"
                          v-model="inputs.sepa.iban"
                          v-validate="ibanValidation()"
                          name="iban"
                          class="form-control"
                          :mask="ibanMask()"
                          :placeholder="ibanPlaceholder()"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="alert alert-dark" role="alert" v-if="isWWW">
                        <strong>SEPA-Lastschriftmandant für SEPA-Basislastschriften</strong><br>
                        <strong>Gläubigeridentifikation: {{ getSEPA().id }}</strong><br><br>
                        Ich ermächtige/Wir ermächtigen die {{ getSEPA().name }}, Zahlungen von
                        meinem/unserem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein/
                        weisen
                        wir unser Kreditinstitut an, die von der {{ getSEPA().name }} auf
                        mein/unser
                        Konto gezogenen Lastschriften einzulösen.
                        <br><br>
                        Hinweis: Ich kann/Wir können innerhalb von acht Wochen, beginnend mit dem
                        Belastungsdatum, die Erstattung des belasteten Betrages verlangen.
                        Es gelten dabei die mit meinem/unserem Kreditinstitut vereinbarten Bedingungen.
                      </div>
                      <div class="alert alert-dark" role="alert" v-else>
                        <strong>SEPA-Lastschriftmandant für SEPA-Basislastschriften</strong><br>
                        <strong>Gläubigeridentifikation: {{ getSEPA().id }}</strong><br><br>
                        Ich vertrete als Versicherungsmakler/in den/die Antragsteller/in (Kunde)
                        gegenüber der CyberDirekt GmbH
                        ("CyberDirekt") und {{ getSEPA().name }} und versichere, dass ich
                        umfassend zum Empfang und
                        zur Abgabe
                        von Willenserklärungen mit Wirkung für und gegen den Kunden bevollmächtigt bin.
                        Diese Vollmacht
                        umfasst insbesondere die Erteilung eines SEPA-Lastschriftmandats.
                        <br><br>
                        Hiermit erteile ich {{ getSEPA().name }} im Auftrag des Kunden ein
                        SEPA-Lastschriftmandat
                        und
                        autorisiere {{ getSEPA().name }}, Zahlungen von dem Bankkonto des von
                        mir vertretenen Kunden
                        mittels
                        Lastschrift einzuziehen und weise das Kreditinstitut des Kunden an, die von
                        {{ getSEPA().name }}
                        gezogenen Lastschriften einzulösen. Der Kunde kann innerhalb von acht Wochen,
                        beginnend mit dem
                        Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten
                        dabei die mit der Bank
                        des Kunden vereinbarten Bedingungen.
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4 hr-wrapper">
          <hr>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <h2>Ihre Versicherung</h2>
          </div>
          <div class="col-12 col-md-7">
            <div class="row">
              <div class="col-12 col-md-6 form-group">
                <label>Versicherungsbeginn</label>
                <datepicker placeholder="heute"
                            format="dd.MM.yyyy"
                            input-class="form-control"
                            :language="de"
                            :monday-first="true"
                            :disabledDatesMixin="inputs.insurer.includes('victor') ? victorDisabledDatesMixin : disabledDatesMixin"
                            v-model="inputs.startDate"></datepicker>
              </div>
              <transition name="fade">
                <div class="col-12 col-md-6 form-group" v-if="inputs.dueDate && (inputs.insurer !== 'vhv' || inputs.insurer !== 'vhv-vodafone')">
                  <label>Hauptfälligkeit</label>
                  <select class="form-control"
                          name="beginOfQuarter"
                          id="beginOfQuarter"
                          v-model="beginOfQuarter">
                    <option v-for="(possibleDueDate, idx) in possibleDueDates" :key="'possibleDueDate-' + idx"
                            :value="possibleDueDate.month">{{ possibleDueDate.label }}
                    </option>
                  </select>
                </div>
              </transition>
              <div class="col-12 col-md-6 form-group" v-if="inputs.insurer==='vhv' || inputs.insurer==='vhv-vodafone'">
                <label for="beginOfQuarterOptional">
                  Abweichende Hauptfälligkeit
                </label>
                <select class="form-control"
                        name="beginOfQuarter"
                        id="beginOfQuarterOptional"
                        v-model="beginOfQuarter"
                        :disabled="beginOfQuarterDisabled">
                  <option v-for="(possibleDueDate, idx) in possibleDueDates" :key="'possibleDueDate-' + idx"
                          :value="possibleDueDate.month">{{ possibleDueDate.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row" v-if="isDeviatingDue">
              <div class="col-12 col-md-7 form-group" id="date_checker">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox"
                        class="custom-control-input"
                        id="dueData"
                        v-model="inputs.dueDate"
                        :disabled="dueDateDisabled">
                  <label v-if="inputs.insurer === 'vhv' || inputs.insurer==='vhv-vodafone'" class="custom-control-label" for="dueData">Abweichende Hauptf&auml;lligkeit</label>
                  <label v-else class="custom-control-label" for="dueData">Abweichende Hauptf&auml;lligkeit gew&uuml;nscht</label>
                  <small v-if="inputs.insurer === 'vhv' || inputs.insurer==='vhv-vodafone'">Rechnungsstellung zum 01.01</small>
                  <small v-else>Rechnungsstellung zum Quartalsbeginn</small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 form-group">
                <label for="additionalNotes">Anmerkungen zum Antrag</label>
                <div class="fields-bucket">
                  <textarea
                    class="form-control" type="text" id="additionalNotes" name="additionalNotes" rows="4"
                    v-model="inputs.additionalNotes"
                    :placeholder="addNotesPlaceholder"
                    @focus="($event) => $event.target.placeholder = ''"
                    @blur="($event) => $event.target.placeholder = addNotesPlaceholder"
                    @change="($event) => $event.target.value = $event.target.value.trim()" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr>
              </div>
            </div>
            <div class="row checkboxes">
              <div class="col-12">
                <h3>Bestätigung der Versicherungsbedingungen</h3>
                <div class="custom-control custom-checkbox custom-checkbox--collapsible mb-2"
                    :class="getFieldClass('terms-check-2')">
                  <input type="checkbox"
                        class="custom-control-input"
                        id="terms-check-2"
                        name="terms-check-2"
                        v-validate="'required'"
                        v-model="inputs.checkRevocation">
                  <label class="custom-control-label"
                        for="terms-check-2">
                    <strong>Allgemeine Gesch&auml;ftsbedingungen und Datenschutz</strong>
                    <b-collapse v-model="showRevocation" id="revocationCollapse">
                      <p>
                        Ich akzeptiere die
                        <a href="https://www.cyberdirekt.de/agb/" target="_blank">Allgemeine Gesch&auml;ftsbedingungen</a>
                        der CyberDirekt GmbH (&quot;CyberDirekt&quot;) und habe die
                        <a href="https://www.cyberdirekt.de/datenschutzhinweise/" target="_blank">Datenschutzhinweise</a>
                        erhalten.
                      </p>
                    </b-collapse>
                  </label>
                  <button class="btn btn-icon"
                          type="button"
                          v-b-toggle.collapse2
                          :class="{'is-collapsed': showRevocation}"
                          @click="showRevocation = !showRevocation">
                    <icon name="chevron-down" color="secondary" :width="'32'"></icon>
                  </button>
                </div>
                <div class="custom-control custom-checkbox custom-checkbox--collapsible mb-2"
                    v-if="!isWWW"
                    :class="getFieldClass('terms-check-4')">
                  <input type="checkbox"
                        class="custom-control-input"
                        id="terms-check-4"
                        name="terms-check-4"
                        v-validate="'required'"
                        v-model="inputs.checkBrokerTerms">
                  <label class="custom-control-label"
                        for="terms-check-4">
                    <strong>Maklervollmacht &amp; Einwilligungserkl&auml;rung des Kunden</strong>
                    <b-collapse v-model="showBrokerLegal1" id="brokerLegalCollapse">
                      <p>
                        Ich vertrete als Versicherungsmakler den/die Antragsteller/in (Kunde)
                        gegenüber CyberDirekt GmbH
                        ("CyberDirekt") und {{ getSEPA().name }} und versichere, dass ich
                        umfassend zum Empfang
                        und zur
                        Abgabe von Willenserklärungen mit Wirkung für und gegen den Kunden
                        bevollmächtigt bin und etwaige
                        Untervollmachten wirksam erteilt wurden.
                      </p>
                      <p>
                        Weiterhin versichere ich, dass mir von dem Kunden eine Vollmacht zur Abgabe
                        datenschutzrechtlich
                        relevanter Erklärungen und Einwilligungen zur Nutzung, Speicherung und
                        Verwendung seiner
                        personenbezogenen Daten durch die CyberDirekt GmbH ("CyberDirekt") wirksam erteilt
                        wurde bzw. mir eine
                        vom Kunden unterzeichnete datenschutzrechtliche Einwilligungserklärung der
                        CyberDirekt GmbH
                        ("CyberDirekt") vorliegt. Vor Erteilung dieser Vollmacht oder
                        Einwilligungserklärung habe ich den
                        Kunden über die Nutzung, Speicherung und Verwendung seiner personenbezogenen
                        Daten durch CyberDirekt GmbH
                        ("CyberDirekt") umfassend aufgeklärt
                      </p>
                    </b-collapse>
                  </label>
                  <button class="btn btn-icon"
                          type="button"
                          v-b-toggle.collapse4
                          :class="{'is-collapsed': showBrokerLegal1}"
                          @click="showBrokerLegal1 = !showBrokerLegal1">
                    <icon name="chevron-down" color="secondary" :width="'32'"></icon>
                  </button>
                </div>
                <div class="custom-control custom-checkbox custom-checkbox--collapsible mb-2"
                    :class="getFieldClass('terms-check-3')">
                  <input type="checkbox"
                        class="custom-control-input"
                        id="terms-check-3"
                        name="terms-check-3"
                        v-validate="'required'"
                        v-model="inputs.checkContractDocuments">
                  <label class="custom-control-label"
                        for="terms-check-3">
                    <strong v-if="isWWW">Vertragsdokumente</strong>
                    <strong v-else>Erhalt und Weitergabe relevanter Dokumente</strong>
                    <b-collapse v-model="showContractDocuments" id="contractDocumentsCollapse">
                      <p v-if="isWWW">Ich akzeptiere die nachfolgenden Vertragsdokumente der
                        {{ getSEPA().name }}, sowie
                        die Mitteilung &sect;19 Abs. 5 VVG und die Widerrufsbelehrung nach &sect; 8
                        Abs. 2 Nr. 2 VVG vor
                        Antragsstellung und erhalte diese per E-mail.</p>
                      <p v-else>
                        Ich best&auml;tige, dass ich f&uuml;r den Kunden rechtzeitig vor
                        Vertragsschluss folgende
                        Dokumente erhalten
                        und diese dem Kunden ausgeh&auml;ndigt habe (die angegebenen Dokumente sind
                        auch weiterhin
                        jederzeit
                        abrufbar im Kundenportal.
                        <!-- FIXME: the v-if -->
                        <span v-if="insurer.key !== 'zurich'">
                          Der Kunde bekommt diese Dokumente zus&auml;tzlich mit der Police zugeschickt
                        </span>
                        ):
                      </p>
                      <file-list class="mb-3" :items="downloadItems"></file-list>
                    </b-collapse>
                  </label>
                  <button class="btn btn-icon"
                          type="button"
                          v-b-toggle.collapse3
                          :class="{'is-collapsed': showContractDocuments}"
                          @click="showContractDocuments = !showContractDocuments">
                    <icon name="chevron-down" color="secondary" width="32"></icon>
                  </button>
                </div>
                <div class="custom-control custom-checkbox custom-checkbox--collapsible mb-2"
                    :class="getFieldClass('terms-check-5')"
                    v-if="!isWWW">
                  <input type="checkbox"
                        class="custom-control-input"
                        id="terms-check-5"
                        name="terms-check-5"
                        v-validate="'required'"
                        v-model="inputs.checkVVG">
                  <label class="custom-control-label"
                        for="terms-check-5">
                    <strong>Vorvertragliche Anzeigepflicht</strong>
                    <b-collapse v-model="showVVG" id="vvgCollapse">
                      <p v-if="countryInterface === 'AT'">
                        Ich bestätige, dass ich den Kunden belehrt habe, dass es sich bei den
                        gestellten Risikofragen um Fragen des Versicherers i.S.v. § 16 Abs. 1 VersVG
                        handelt. Ich habe den Kunden über die Rechtsfolgen einer vorvertraglichen
                        Anzeigepflicht gem. §§ 16 ff. VersVG durch Aushändigung des Hinweises zur
                        Belehrung
                        über die Folgen einer Verletzung der vorvertraglichen Anzeigepflicht
                        belehrt.
                      </p>
                      <p v-else>
                        Ich bestätige, dass ich den Kunden belehrt habe, dass es sich bei den
                        gestellten Risikofragen um Fragen des Versicherers i.S.v. § 19 Abs. 1 VVG
                        handelt. Ich habe den Kunden über die Rechtsfolgen einer vorvertraglichen
                        Anzeigepflicht gem. § 19 Abs. 5 VVG durch Aushändigung des Hinweises zur
                        Belehrung
                        über die Folgen einer Verletzung der vorvertraglichen Anzeigepflicht
                        belehrt.
                      </p>
                    </b-collapse>
                  </label>
                  <button class="btn btn-icon"
                          type="button"
                          v-b-toggle.collapse5
                          :class="{'is-collapsed': showVVG}"
                          @click="showVVG = !showVVG">
                    <icon name="chevron-down" color="secondary" :width="'32'"></icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="row" v-if="isWWW">
              <div class="col-12">
                <div class="alert alert-dark" role="alert">
                  Wir sind rechtlich dazu angehalten Sie zu fragen, ob Sie eine Beratung durch uns oder
                  den Versicherer
                  ben&ouml;tigen und dies dokumentiert haben m&ouml;chten oder ob Sie auf die Beratung und
                  Dokumentation
                  durch uns sowie durch den Versicherer verzichten wollen. Mit einem Verzicht auf Beratung
                  und
                  Dokumentation k&ouml;nnen Sie direkt mit dem Antrag fortfahren und ihre {{
                    'Cyber-Versicherung'
                  }} schnell und
                  einfach abschlie&szlig;en.<br>
                  Wir sind verpflichtet, Sie dar&uuml;ber zu informieren, dass sich Ihr Verzicht auf
                  Beratung und
                  Dokumentation nachteilig auf Ihre M&ouml;glichkeit der Geltendmachung von
                  Schadensersatzanspr&uuml;chen
                  gegen uns oder den Versicherer wegen Verletzung von Beratungs- und
                  Dokumentationspflichten auswirken
                  kann. Falls Sie also „Beratung erw&uuml;nscht“, haben Sie die M&ouml;glichkeit, einen
                  pers&ouml;nlichen
                  Ansprechpartner f&uuml;r eine Beratung zu kontaktieren.
                </div>
              </div>
            </div>
            <div class="row mb-4" v-if="isWWW">
              <div class="col-6">
                <div class="custom-control custom-radio">
                  <input type="radio"
                        id="radioAdviceYes"
                        name="radioAdvice"
                        :value="false"
                        v-model="inputs.needAdvice"
                        class="custom-control-input">
                  <label class="custom-control-label" for="radioAdviceYes">
                    <strong>Ich verzichte auf Beratung</strong>
                  </label>
                </div>
              </div>
              <div class="col-6">
                <div class="custom-control custom-radio">
                  <input type="radio"
                        id="radioAdviceNo"
                        name="radioAdvice"
                        :value="true"
                        v-model="inputs.needAdvice"
                        class="custom-control-input">
                  <label class="custom-control-label text-muted" for="radioAdviceNo">
                    Beratung erw&uuml;nscht
                  </label>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12">
                <button type="button"
                        class="btn btn-secondary"
                        :disabled="isSubmitting || submitDisabled()"
                        @click="successContract()">
                  <icon class="mr-2" name="loading" color="white" :spinning="true" v-if="isSubmitting"></icon>
                  kostenpflichtig beantragen
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p v-if="countryInterface === 'AT'" class="text-muted">
                  Durch Dr&uuml;cken des Knopfes &quot;kostenpflichtig beantragen&quot; wird ein rechtlich
                  bindender
                  Antrag auf Abschluss eines Versicherungsvertrags an den Versicherer gesendet. Mit der
                  Absendung kommt kein Vertrag &uuml;ber eine vorl&auml;ufige Deckung
                  zustande.
                  Der Antrag wird zun&auml;chst vom Versicherer auf seine Annahmef&auml;higkeit hin gepr&uuml;ft.
                  Anschlie&szlig;end erhalten Sie eine Mitteilung, ob ein Versicherungsvertrag
                  abgeschlossen
                  wurde.
                  <br><br>
                  Sollte eine weitergehende Pr&uuml;fung notwendig sein, wird sich ein Mitarbeiter hierzu
                  mit
                  Ihnen pers&ouml;nlich in Verbindung setzen.
                </p>
                <p v-else class="text-muted">
                  Durch Dr&uuml;cken des Knopfes &quot;kostenpflichtig beantragen&quot; wird ein rechtlich
                  bindender
                  Antrag auf Abschluss eines Versicherungsvertrags an den Versicherer gesendet. Mit der
                  Absendung kommt kein Vertrag &uuml;ber eine vorl&auml;ufige Deckung nach &sect;&sect;
                  49ff. VVG
                  zustande.
                  Der Antrag wird zun&auml;chst vom Versicherer auf seine Annahmef&auml;higkeit hin gepr&uuml;ft.
                  Anschlie&szlig;end erhalten Sie eine Mitteilung, ob ein Versicherungsvertrag
                  abgeschlossen
                  wurde.
                  <br><br>
                  Sollte eine weitergehende Pr&uuml;fung notwendig sein, wird sich ein Mitarbeiter hierzu
                  mit
                  Ihnen pers&ouml;nlich in Verbindung setzen.
                </p>
              </div>
            </div>
          </div>
        </div>
        <input type="submit" id="step_submit">
      </div>
    </form>
    <transition name="fade">
      <div class="advice-bar" v-if="inputs.needAdvice">
        <div class="container">
          <div class="row">
            <div class="col-12 d-flex flex-column flex-md-row align-items-center">
              <div class="advice-bar__phone text-center">Haben Sie noch Fragen?<br>030 403 660 36</div>
              <div class="advice-bar__working-hours text-center">Wir sind für Sie erreichbar<br>werktags
                09:00 - 18:00 Uhr
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import eventBus from '@/components/shared/EventBus.js'
import Datepicker from 'vuejs-datepicker'
import {de} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import ContractDataForm from './ContractDataForm.vue';

import FileList from '../FileList';
import Icon from '../Icon'

import {BCollapse, VBToggle} from 'bootstrap-vue'
import Vue from 'vue';
import StringHelper from '../StringHelper';
// import holidays from '../holidays';
import accounting from 'accounting';
import store from '@/store/index';
import '../validation/ibanValidation.js'
import preventInput from '@/mixins/validation/preventInput'
import isStepAllowed from '../mixins/isStepAllowed.js'
import agencyKey from '../mixins/agencyKey';
import riskQuestionsMixin from '../mixins/riskQuestionsMixin.js'
import insuranceDatesMixin from '../mixins/insuranceDatesMixin.js'

Vue.directive('b-toggle', VBToggle);

export default {
  name: 'Step4',
  data() {
    return {
      isSubmitting: false,
      showContractDocuments: false,
      showRevocation: false,
      checkContractDocuments: false,
      checkRevocation: false,
      showVVG: false,
      showBrokerLegal1: false,
      de: de,
      submitted: false,
      beginOfQuarter: 1,
      possibleDueDates: [
        {
          month: 0,
          label: '01.01.'
        },
        {
          month: 3,
          label: '01.04.'
        },
        {
          month: 6,
          label: '01.07.'
        },
        {
          month: 9,
          label: '01.10.'
        },
      ],
      hasPriceOverWrite: null,
      beginOfQuarterDisabled: false,
      dueDateDisabled: false,
      isCompanyMax60Alerted: false,
      isSepaCompanyMax60Alerted: false,
      addNotesPlaceholder: `Bitte beachten Sie, dass Anmerkungen kein automatischer Bestandteil des Antrags sind und lediglich durch CyberDirekt auf Vertragsrelevanz geprüft werden. Gegebenenfalls leiten wir Ihre Anmerkung weiter zur Bearbeitung an den Versicherer.
Bei Fragen kontaktieren Sie uns: makler@cyberdirekt.de`
    }
  },
  computed: {
    ...mapGetters([
      'isWWW',
      'isBroker',
      'prices'
    ]),
    countryInterface() {
      return this.$store.state.checkout.application.countryInterface.value
    },
    isInvoicePaymentPossible() {
      return !(this.inputs.insurer === 'vhv' ||this.inputs.insurer === 'vhv-vodafone' ||
          (this.inputs.paymentPeriod !== 'yearly') ||
          (this.inputs.insurer === 'axa-firmenflex' && this.inputs.paymentPeriod === 'monthly'));


    },
    title() {
      return StringHelper.get(this.$store, 'STEP_4_TITLE');
    },
    companyDataText() {
      return StringHelper.get(this.$store, 'STEP_4_COMPANY_DATA');
    },
    inputs() {
      return this.$store.state.checkout.application.inputs;
    },
    offer() {
      return this.$store.state.checkout.application.inputs.offer;
    },
    insurer() {
      return this.$store.state.checkout.application.insurers.find(insurer => insurer.key === this.$store.state.checkout.application.inputs.insurer);
    },
    insurers() {
      return this.$store.state.checkout.application.insurers;
    },
    insurerKey() {
      return this.inputs.insurer
    },
    price() {
      if (this.hasPriceOverWrite && this.hasPriceOverWrite[this.insurerKey]) {
        return this.hasPriceOverWrite[this.insurerKey]
      }
      return this.prices[this.insurerKey]
    },
    downloadItems() {
      let {files} = this.insurer;

      // filter files based on admin settings
      files = files.filter(file => {
        return file.allowedLocations && file.allowedLocations.showInStepFive
      })

      return files.filter((file) => {
        const zurichAndCyberbetrug = (this.inputs.insurer === 'zurich' && this.inputs.selectedModules[this.inputs.insurer].includes('cyberbetrug'))
        const zurichAndNoCyberbetrug = (this.inputs.insurer === 'zurich' && !this.inputs.selectedModules[this.inputs.insurer].includes('cyberbetrug'))
        if (zurichAndCyberbetrug) {
          switch (file.insuredSum) {
            case true:
              return !file.link.includes('ohneCyberBetrug') && file.link.includes(`_${this.inputs.insuredSum}.`)
            default:
              return !file.link.includes('ohneCyberBetrug')
          }
        } else if (zurichAndNoCyberbetrug) {
          switch (file.insuredSum) {
            case true:
              return !file.link.includes('mitCyberBetrug') && file.link.includes(`_${this.inputs.insuredSum}.`)
            default:
              return !file.link.includes('mitCyberBetrug')
          }
        } else {
          return !(file.module && this.inputs.selectedModules[this.inputs.insurer] &&
              !file.module.some(fileModule => this.inputs.selectedModules[this.inputs.insurer].indexOf(fileModule) > -1));
        }
      });
    },
    questions() {
      return this.$store.state.checkout.application.questions?.[this.inputs.insurer]?.filter(questionEl => {
        return Object.keys(this.inputs.answers).includes('q-' + questionEl.id) || Object.keys(this.inputs.form).includes(questionEl.id)
      })
    },
    isIndication() {
      // ToDo: create a dynamic DB flag for it
      // currently only used for all Victor tariffs over 25M revenue (excl. victor-architekten-ingenieure)
      // and all Corvus tariffs on WWW
      const isVictor = this.inputs.insurer !== 'victor-architekten-ingenieure' && this.inputs.insurer.includes('victor');
      const isHighRevenue = this.inputs.netTurnOver > 25000000;

      const corvusKeys = [
        'corvus-rc1-standard',
        'corvus-rc2-standard',
        'corvus-rc3-standard',
        'corvus-rc4-standard',
        'corvus-rc5-standard'
      ];

      const isCorvus = this.inputs.insurer && corvusKeys.some(key => this.inputs.insurer.includes(key));

      const baobabKeys = [
        'baobab-standard-tg1',
        'baobab-standard-tg2',
        'baobab-standard-tg3'
      ];

      const isBaobab = this.inputs.insurer && baobabKeys.some(key => this.inputs.insurer.includes(key));

      const stoikKeys = [
        'stoik-sideletter-rc1',
        'stoik-sideletter-rc1-gte-3mio',
        'stoik-sideletter-rc2',
        'stoik-sideletter-rc2-gte-3mio',
        'stoik-sideletter-rc3',
        'stoik-sideletter-rc3-gte-3mio',
        'stoik-sideletter-rc4',
        'stoik-sideletter-rc4-gte-3mio',
        'stoik-sideletter-rc5',
        'stoik-sideletter-rc5-gte-3mio'
      ];

      const isStoik = this.inputs.insurer && stoikKeys.some(key => this.inputs.insurer.includes(key));

      return ((isVictor && isHighRevenue) || isCorvus || isBaobab || isStoik);
    },
    isDeviatingDue () {
      return this.inputs.insurer !== 'vhv' && this.inputs.insurer !== 'vhv-vodafone' && this.inputs.insurer !== 'axa-firmenflex' && this.inputs.insurer !== 'zurich';
    }
  },
  props: {
    step: Object
  },
  components: {
    Datepicker,
    FileList,
    Icon,
    BCollapse,
    ContractDataForm,
  },
  directive: {
    VBToggle
  },
  mounted: function () {
    eventBus.$emit('stepChangeCompleted');
    eventBus.$emit('disableHdrNext', 'hide');
    eventBus.$on('submitStep', () => {
      document.getElementById('step_submit').click();
    });

    if (this.getSEPA().isDisabled) {
      this.inputs.paymentMethod = 'invoice';
    } else if (this.inputs.paymentPeriod === 'yearly') {
      this.inputs.paymentMethod = this.inputs.paymentMethod || 'directDebit';
    } else {
      this.inputs.paymentMethod = 'directDebit';
    }

    // apply rules for startdates from mixin
    this.inputs.startDate = this.modifyStartDateMixin(this.inputs.insurer, this.inputs.startDate);

    if (this.inputs.billingDate) {
      const billingDate = moment(this.inputs.billingDate);
      const month = billingDate.month();

      if ([0, 3, 6, 9].indexOf(month) > -1) {
        this.beginOfQuarter = month;
      } else {
        this.beginOfQuarter = null;
      }
    }
    if (this.inputs.insurer === 'vhv' || this.inputs.insurer === 'vhv-vodafone') {
      this.beginOfQuarter = 0;
      this.beginOfQuarterDisabled = true;
      this.dueDateDisabled = true;
      this.inputs.dueDate = true;
    }
    window.scrollTo(0, 0);
  },
  async beforeRouteEnter(to, from, next) {
    const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store
    const hasPriceOverWrite = await storeInstance.dispatch('STEP4_DATA_UPDATE')
    next(vm => {
      vm.hasPriceOverWrite = hasPriceOverWrite || null;
    });
  },
  async beforeRouteUpdate(to, from, next) {
    const storeInstance = window && window.$nuxt ? window.$nuxt.$store : store
    await storeInstance.dispatch('STEP4_DATA_UPDATE')
    next();
  },
  methods: {
    getInsuredSum(insuredSum) {
      // TODO: need generic function here to change visible insuredSum
      return this.insurer.key === 'condor' && insuredSum === 100000 ? 125000 : insuredSum;
    },
    getInsurerLogoPath(insurer) {
      return insurer.logoUrl;
    },
    submitForm() {
      this.isSubmitting = true;
      this.$validator
          .validateAll()
          .then((result) => {
            let errorEl;
            let data = {...this.inputs};
            let affiliate = this.$cookie ? this.$cookie.get('cd-aff') : this.$cookies.get('cd-aff');
            let partnerId = this.$cookie ? this.$cookie.get('cd-pid') : this.$cookies.get('cd-pid');

            data.selectedModules = data.selectedModules[data.insurer];
            data.net = accounting.toFixed(this.price.net, 2);
            data.gross = accounting.toFixed(this.price.gross, 2);
            data.type = 'cyber';
            data.insurer = this.inputs.insurer;
            data.questions = this.questions;
            data.meta = data.meta[data.insurer];
            data.indication = this.isIndication;

            if (affiliate || partnerId) {
              data.affiliate = true;

              if (affiliate) {
                data.affiliateId = affiliate;
              }

              if (partnerId) {
                data.partnerId = partnerId;
              }
            }

            if (result) {
              if (this.submitted) {
                return;
              }

              this.submitted = true;
              // this was just producing empty arrays as the session does not seem to be used anymore.
              // if (this.isBroker) {
              //   this.$store.dispatch('UPDATE_INPUT_VALUES', {
              //     answeredQuestions: this.$session.get('answeredVectors') || []
              //   });
              // }

              if (this.inputs.paymentMethod === 'invoice') {
                for (let item in this.inputs.sepa) this.inputs.sepa[item] = ''
                delete data.sepa;
              }
              // clear up forms that have not been answered correctly
              data.form = this.removeUnusedFormsMixin(data.questions, data.answers, data.form)
              data.questions = this.$store.getters.filteredQuestions()

              this
                  .$store
                  .dispatch('INSERT_REGISTRATION', data)
                  .then(() => {
                    if (window.fbq) {
                      window.fbq('track', 'Purchase', {
                        value: data.gross,
                        currency: 'EUR',
                        content_ids: 'insurance',
                        content_type: data.type
                      });
                    }
                    this.step.completed = true;

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth"
                    });

                    eventBus.$emit('changeStepForward', this.step);
                  })
                  .catch((err) => {
                    const {error} = err.response.data;
                    const message = (error.message && error.message.indexOf('Email already exists') > 1)
                        ? 'Diese E-Mail-Adresse wurde bereits verwendet.'
                        : error.message === 'unacceptable_answers_provided'
                        ? 'Aufgrund der Beantwortung der Risikofragen kann der Vertrag nicht automatisch policiert werden, sondern muss vom Versicherer individuell geprüft werden.'
                        : 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns telefonisch unter 030-40369529 oder per Mail makler@cyberdirekt.de.';

                      this.isSubmitting = false;

                      eventBus.$emit('alertUser', {
                        type: 'error',
                        message: message,
                        customCloseBtnText: 'OK',
                        customCloseBtnClass: 'btn btn-primary'
                      })
                  })
            } else {
              errorEl = document.getElementById(this.$validator.errors.items[0].field);
              const errorRule = this.$validator.errors.items[0].rule

              if (!errorEl) {
                errorEl = document.querySelector(`[name="${this.$validator.errors.items[0].field}"]`);
              }

              this.isSubmitting = false;

              if (errorEl) {
                errorEl.scrollIntoView({ block: "center" });
                errorEl.focus();
              }
            }
          });
    },
    getFieldClass(fieldName) {
      if (!this.fields[fieldName]) {
        return;
      }

      return {
        'is-danger': this.errors.has(fieldName),
        'touched': this.fields[fieldName].dirty
      }
    },
    getSEPA() {

      // group different products to one insurer key
      // key = key.indexOf('victor') > -1 ? 'victor' : key;
      // key = key.indexOf('mailo') > -1 ? 'mailo' : key;
      // key = key.indexOf('hiscox') > -1 ? 'hiscox' : key;
      // key = key.indexOf('axa') > -1 ? 'axa' : key;
      // key = key.indexOf('ergo') > -1 ? 'ergo' : key;
      // key = key.indexOf('continentale') > -1 ? 'continentale' : key;

      let SEPA = this.$store.state.checkout.application.prices[this.insurerKey].sepa;

      if(!SEPA) {
        console.error('missing sepa data for insurer')
      }

      return {
        isDisabled: Boolean(SEPA.sepaPaymentsDisabled),
        name: SEPA.insurerSepaName,
        id: SEPA.insurerSepaId
      };
    },
    successContract() {
      this.inputs.type = 'contract';

      if (this.isIndication) {
        return eventBus.$emit('alertUser', {
          type: 'warning',
          message: 'Nach Beantragung leiten wir die Angaben zur individuellen Prüfung an den Anbieter weiter. Bei positivem Bescheid entsteht ein Vertrag und Sie erhalten anschließend manuell per Mail die Unterlagen.',
          customCloseBtnText: 'Abbrechen',
          customConfirmBtnText: 'Indikation an den Anbieter weiterleiten',
          customCloseBtnClass: 'btn',
          customConfirmBtnClass: 'btn btn-secondary btn-break mb-2',
          useConfirmBtn: true,
          onConfirm: () => {
            this.submitForm();
          }
        });
      }

      this.submitForm();
    },
    copyAddress() {
      this.inputs.sepa.firstname = this.inputs.firstname;
      this.inputs.sepa.lastname = this.inputs.lastname;
      this.inputs.sepa.company = this.inputs.company;
      this.inputs.sepa.street = this.inputs.street;
      this.inputs.sepa.postal = this.inputs.postal;
      this.inputs.sepa.city = this.inputs.city;
    },
    getPaymentPeriod() {
      let paymentPeriods = {
        yearly: 'jährlich',
        halfyearly: 'halbjährlich',
        quarterly: 'vierteljährlich',
        monthly: 'monatlich'
      };
      return paymentPeriods[this.inputs.paymentPeriod];
    },
    submitDisabled() {
      if (this.isWWW) {
        return this.inputs.needAdvice !== false;
      }
    },
    handleCompanyMax60(companyInput = 'company') {
      const self = this
      function showPopup() {
        self.$Simplert.open({
          type: 'error',
          message: 'Aufgrund Platzmangels in Police und Rechnung kann der Name des Versicherungsnehmers bei mehr als 60 Zeichen bei der automatischen Dokumentenerstellung nicht vollständig abgebildet werden, sondern wird nach dem 60. Zeichen abgeschnitten. Sie können den Vertrag dennoch problemlos einreichen.',
          customCloseBtnText: 'OK',
          customCloseBtnClass: 'btn btn-primary ',
        })
      }

      if (companyInput === 'company') {
        if (!this.isCompanyMax60Alerted) {
          showPopup()
          this.isCompanyMax60Alerted = true
        }
      } else if(companyInput === 'sepa.company') {
        if (!this.isSepaCompanyMax60Alerted) {
          showPopup()
          this.isSepaCompanyMax60Alerted = true
        }
      }

    },
    postalValidation () {
      const map = {
        "DE": "required|numeric|min:5|max:5",
        "AT": "required|numeric|min:4|max:4|1st-char-not-0"
      }
      return map[this.countryInterface]
    },
    ibanValidation () {
      const map = {
        "DE": "required|iban:DE",
        "AT": "required|iban:AT"
      }
      return map[this.countryInterface]
    },
    ibanMask() {
      const map = {
        "DE": "AA## #### #### #### #### ##",
        "AT": "AA## #### #### #### ####"
      }
      return map[this.countryInterface]
    },
    ibanPlaceholder () {
      const map = {
        "DE": "DE12 5001 0517 0648 4898 90",
        "AT": "AT48 3200 0000 1234 5864"
      }
      return map[this.countryInterface]
    },
    postalPlaceholder () {
      const map = {
        "DE": "10115",
        "AT": "4030"
      }
      return map[this.countryInterface]
    },
    cityPlaceholder () {
      const map = {
        "DE": "Berlin",
        "AT": "Wien"
      }
      return map[this.countryInterface]
    }
  },
  watch: {
    'inputs.dueDate'(newValue) {
      if (newValue) {
        this.inputs.billingDate = moment().add(1, 'year').startOf('year').hours(12).toDate();
        this.beginOfQuarter = 0;
      } else {
        this.inputs.billingDate = moment().hours(12).toDate()
      }
    },
    beginOfQuarter(newValue) {
      if (newValue === null || typeof newValue === 'undefined') return;
      let billingDate = moment().month(newValue).date(1).hours(12);

      if (billingDate.isBefore(moment())) {
        billingDate.add(1, 'year');
        this.inputs.billingDate = billingDate.toDate();
      } else {
        this.inputs.billingDate = billingDate.toDate();
      }
    },
    'inputs.sepa.company'(newValue) {
      this.insurer.key.includes('victor') && !this.isSepaCompanyMax60Alerted && newValue.length > 60 ? this.handleCompanyMax60('sepa.company') : ''
    },
  },
  destroyed: function () {
    eventBus.$off('submittext-centerStep');
    eventBus.$emit('disableHdrNext', false);
  },
  mixins: [isStepAllowed, preventInput, agencyKey, riskQuestionsMixin, insuranceDatesMixin]
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/form';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';


.phone-resolver-container {
  border: none;
  padding-top: 0;
  padding-left: 0;
}

p {
  font-size: 1rem;
}

.btn-secondary {
  color: #fefefe;
  background-color: #f26722 !important;
  border-color: #f26722 !important;
}

::v-deep h2 {
  font-size: 1.5rem;
  color: $darkBlueTone !important;
  font-weight: bold;
}

h3 {
  font-size: 1.3rem;
}

.row {
  margin-bottom: 16px;
}

.contract-details {
  margin-bottom: 64px;
  padding: 16px 0 !important;
  background: $lightGrey;
  border-top: 2px solid $lightGrey3;
  border-bottom: 2px solid $lightGrey3;

  @include media-breakpoint-down(sm) {
    margin-bottom: 2.5rem;
  }

  &__item {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: 122px;

    + .contract-details__item {
      border-top: 2px solid $lightGrey3;

      @include viewport-large-up {
        border-left: 2px solid $lightGrey3;
        border-top: 0;
      }
    }

    @include viewport-large-up {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.contract-detail {
  &__header {
    margin-bottom: 16px;
    font-weight: bold;
  }

  &__content {
    flex: 1;

    + .contract-detail__content {
      margin-top: 16px;
      padding-top: 16px;
      border-top: 2px solid $lightGrey3;
    }
  }

  &__price {
    > div {
      &:first-child {
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    &--small {
      > div {
        &:first-child {
          margin-top: 8px;
          font-weight: 400;
          font-size: .8rem;
        }
      }
    }
  }

  &__logo {
    flex: 1;
    max-width: 50%;
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      min-height: 60px;
    }
  }

  &__logo--ergo-bauwirtschaft {
    img {
      min-height:0;
    }
  }

  &__row {
    div {
      font-size: 0.9rem;
      width: 50%;

      &:first-child {
        text-align: right;
      }

      &:last-child {
        text-align: left;
        font-weight: bold;
        margin-left: 10px;
      }
    }
  }
}

.contract-details {
  border: 2px solid $lightGrey3;
}

.advice-bar {
  position: fixed;
  z-index: $zIndexAdviceBar;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 0;
  min-height: 100px;
  background: $darkBlueTone;
  color: #fff;

  &__phone {
    margin-bottom: 8px;
    flex: 1;
    font-weight: bold;

    @include viewport-medium-up() {
      margin-bottom: 0;
      font-size: 1.3rem;
    }
  }

  &__chat {
    margin-bottom: 8px;
    flex: 1;

    .btn {
      font-weight: bold;

      @include viewport-medium-up() {
        font-size: 1.3rem;
      }
    }

    @include viewport-medium-up() {
      margin-bottom: 0;
    }
  }

  &__working-hours {
  }

  .container,
  .row,
  .col-12 {
    height: 100%;
    margin-bottom: 0;
  }
}

.custom-checkbox--collapsible {
  .btn-icon {
    position: absolute;
    right: -20px;
    top: -8px;
    border: 0;
    background: none;
    transition: all .3s ease;

    &:active,
    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &.is-collapsed {
      transform: rotate(180deg);
    }
  }
}

.two-liner {
  label {
    display: flex;
    align-items: flex-end;
    height: 46px;
  }
}

.insurer {
  border: 2px solid $greyTone;
  font-size: 0.9rem;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-only(xs) {
    scroll-snap-align: start;
  }

  &__banner {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    &__text {
      padding: 4px 12px;
      height: 25px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      background: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__arrow {
      border-color: transparent transparent transparent $primary;
      border-style: solid;
      border-width: 0 0 25px 15px;
      width: 0;
      height: 0;
    }

    &--warning {
      .insurer__banner__text {
        background: $redTone;
        font-size: 13px;
      }

      .insurer__banner__arrow {
        border-color: transparent transparent transparent $redTone;
      }
    }

    &--green {
      .insurer__banner__text {
        background: $greenTone;
      }

      .insurer__banner__arrow {
        border-color: transparent transparent transparent $greenTone;
      }
    }

    &--artus {
      .insurer__banner__text {
        background: #FFB200;
      }

      .insurer__banner__arrow {
        border-color: transparent transparent transparent #FFB200;
      }
    }
  }
  &__image {
    padding: 0 16px 16px 16px;

    img {
      display: block;
      width: 100%;
      max-width: 140px;
      margin: 0 auto;
    }
  }
}

.cd-label {
  font-weight: bold;
}

::v-deep .hr-wrapper {
  padding-left: 0;
  padding-right: 0;
}

.checkboxes {
  .custom-checkbox {
    label {
      padding-right: 1.5rem;
    }
    .collapse {
      margin-right: -1.5rem;
    }
  }
}

#additionalNotes {
  min-height: 260px;

  @include media-breakpoint-up(sm) {
    min-height: 140px;
  }

  @include media-breakpoint-up(md) {
    min-height: 210px;
  }

  @include media-breakpoint-up(lg) {
    min-height: 136px;
  }
}
</style>

<style lang="scss">
.btn-break {
  white-space: inherit;
}
</style>
